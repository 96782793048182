import {
    SAVE_DATA_USER_LOGIN,
    DATA_USER_LOGIN,
    DATA_USER_PERMISSIONS,
    SESSION_INACTIVITY,
    VALUE_EMAIL_AZURE,
    ADMIN_PLATFORM,
} from "../../../Constans/Login/Login"

const INIT_STATE = {
    rex_data_user_logingrow : [],
    rex_data_user_login : [],
    rex_data_user_permissions : [],
    rex_session_inactivity: false,
    rex_value_email_azure: '',
    rex_admin_platform: {
        main_text: '',
        secondary_text: '',
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SAVE_DATA_USER_LOGIN: {
            return {
                ...state,
                rex_data_user_logingrow : action.payload
            }
        }
        case DATA_USER_PERMISSIONS: {
            return {
                ...state,
                rex_data_user_permissions : action.payload
            }
        }
        case DATA_USER_LOGIN: {
            return {
                ...state,
                rex_data_user_login : action.payload
            }
        }
        case SESSION_INACTIVITY: {
            return {
                ...state,
                rex_session_inactivity : action.payload
            }
        }
        case VALUE_EMAIL_AZURE: {
            return {
                ...state,
                rex_value_email_azure : action.payload
            }
        }
        case ADMIN_PLATFORM: {
            return {
                ...state,
                rex_admin_platform : action.payload
            }
        }
        default:
            return state;
    }
}
