import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button } from 'antd'
import {
    MenuOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined
} from '@ant-design/icons'
import { format } from 'date-fns'
import { ObtenerListaPreciosReducer } from '../../../Redux/Actions/Administracion/ListaPrecios/ListaPrecios'


const ListaPrecios = (props) => {

    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()

    const [ cargandoListaPrecio, setCargandoListaPrecio ] = useState(false)

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            // <SearchOutlined
            //     style={{
            //         color: filtered ? '#1890ff' : undefined,
            //     }}
            // />
            <MenuOutlined />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    })

    const {
        rex_data_lista_precios
    } = useSelector(({listaPrecios}) => listaPrecios)

    const columns = [
        {
            title: 'Zona',
            dataIndex: 'zona',
            ...getColumnSearchProps('zona'),
            sorter: (a, b) => a.zona.localeCompare(b.zona),
            showSorterTooltip: false,
            render: (zona) => <div title={zona}>{zona}</div>,
            width: '90px'
        },
        {
            title: 'Territorio',
            dataIndex: 'territorio',
            ...getColumnSearchProps('territorio'),
            sorter: (a, b) => a.territorio.localeCompare(b.territorio),
            showSorterTooltip: false,
            render: (territorio) => <div title={territorio}>{territorio}</div>,
            width: '110px'
        },
        {
            title: 'Descripcion',
            dataIndex: 'descripcion',
            ...getColumnSearchProps('descripcion'),
            sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
            showSorterTooltip: false,
            render: (descripcion) => <div title={descripcion}>{descripcion}</div>,
            width: '130px'
        },
        {
            title: 'Valor venta',
            dataIndex: 'valor_venta',
            ...getColumnSearchProps('valor_venta'),
            sorter: (a, b) => a.valor_venta - b.valor_venta,
            showSorterTooltip: false,
            render: (valor_venta) => <div title={valor_venta}>{valor_venta}</div>,
            width: '110px'
        },
        {
            title: 'Fecha inicio',
            dataIndex: 'fecha_inicio',
            ...getColumnSearchProps('fecha_inicio'),
            sorter: (a, b) => a.fecha_inicio.localeCompare(b.fecha_inicio),
            showSorterTooltip: false,
            render: (fecha_inicio) => <div title={format(new Date(fecha_inicio), 'dd/MM/yyyy')}>{format(new Date(fecha_inicio), 'dd/MM/yyyy')}</div>,
            width: '110px'
        },
        {
            title: 'Fecha fin',
            dataIndex: 'fecha_fin',
            ...getColumnSearchProps('fecha_fin'),
            sorter: (a, b) => a.fecha_fin.localeCompare(b.fecha_fin),
            showSorterTooltip: false,
            render: (fecha_fin) => <div title={format(new Date(fecha_fin), 'dd/MM/yyyy')}>{format(new Date(fecha_fin), 'dd/MM/yyyy')}</div>,
            width: '110px'
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            ...getColumnSearchProps('estado'),
            sorter: (a, b) => a.estado.localeCompare(b.estado),
            showSorterTooltip: false,
            render: (estado) => <div title={estado}>{estado}</div>,
            width: '120px'
        }
    ]


    const ObtenerListaPrecios = async () => {
        setCargandoListaPrecio(true)
        await dispatch(ObtenerListaPreciosReducer())
        setCargandoListaPrecio(false)
    }

    useEffect(() => {
        if(rex_data_lista_precios.length == 0){
            ObtenerListaPrecios()
        }
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<Title level={4} style={{marginBottom: 0}}>Lista de precios</Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerListaPrecios()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_lista_precios.length)} registros</div>
                    </div>
                    <Table

                        pagination={{
                            position: ["topRight", "none"],
                        }}
                        scroll={{
                            x: '1500px',
                            y: '330px',
                        }}
                        loading={cargandoListaPrecio}
                        columns={columns} 
                        dataSource={rex_data_lista_precios} 
                        className="Table-Home"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default ListaPrecios