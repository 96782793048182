import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Button, Input, Popconfirm, Pagination, Skeleton, Select, Space } from 'antd'
import {
    ReloadOutlined,
    ArrowLeftOutlined,
    FilterOutlined,
    DeleteOutlined,
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons'
import config from '../../../config'
import '../../../Styles/Components/Administracion/Administrador/Usuarios.css'
import '../../../Styles/Components/Administracion/Administrador/CargaArchivos.css'
import { 
    DeleteFileDescargasArchivosReducer,
    GetDataDescargasArchivosReducer,
    FiltrarTiposArchivoDescargasArchivosReducer,
} from '../../../Redux/Actions/Administracion/Administrador/DescargasArchivos'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

const DescargasArchivos = (props) => {

    const collapsed = props.collapsed
    const { Title } = Typography
    const [ pageTable, setPageTable ] = useState(1)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const {
        rex_data_descargas_archivos,
        rex_loading_data_carga_archivos,
        rex_data_descargas_archivos_copia,
        rex_data_tipos_archivos_descargas_archivos,
    } = useSelector(({descargasArchivos}) => descargasArchivos)

    const ObtenerDescargasArchivos = async () => {
        await dispatch(GetDataDescargasArchivosReducer())
    }

    useEffect(() => {
        if(rex_data_descargas_archivos.length == 0){
            dispatch(GetDataDescargasArchivosReducer())
        }
    },[])

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color:'white'}} />),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                const fecha = value
                const formattDate = format(new Date(record[dataIndex]), 'dd-MM-yyyy HH:mm')
                
                return record[dataIndex] != null ? (formattDate).toString().toLowerCase().includes(fecha.toLowerCase()) : false
            }else{
                return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
            }
        }
    })

    const sorterCustomize = ( sortedColumn, nameColum ) => {
        return (
            <div className='Container-Sorter-Column-Carga-Archivos'>
                <span>{nameColum}</span>
                <div style={{marginLeft:'5px'}}>
                    {sortedColumn ? (
                        sortedColumn.order == 'ascend' 
                        ? ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined /><CaretDownOutlined style={{color:'black', marginTop:'-2px'}}/></div> )
                        : sortedColumn.order == 'descend'
                            ? ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined style={{color:'black'}}/><CaretDownOutlined style={{ marginTop:'-2px'}}/></div> )
                            : ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined style={{color:'black'}} /><CaretDownOutlined style={{color:'black', marginTop:'-2px'}}/></div> )
                    ): ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined style={{color:'black'}} /><CaretDownOutlined style={{color:'black',  marginTop:'-2px'}}/></div> )}
                </div>
            </div>
        )
    }

    const columns = [
        {
            key: 'index',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'index')
                return sorterCustomize(sortedColumn,'N°')
            },
            dataIndex: 'index',
            sorter: (a, b) => a.index - b.index,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.index}</div>,
            align:'center',
        },
        {
            key:'darnombre',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'darnombre')
                return sorterCustomize(sortedColumn, 'Nombre')
            },
            dataIndex: 'darnombre',
            ...getColumnSearchProps('darnombre', 'Nombre Archivo'),
            sorter: (a, b) => a.darnombre? a.darnombre.localeCompare(b.darnombre) : false,
            showSorterTooltip: false,
            render: (_, record) => 
                    <div style={{width:'450px'}}>
                        <a style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}
                            href={`${config.api_url}descargar-data/generar-descarga?token=${record.dartoken}`}
                        >
                            {record.darnombre}
                        </a>
                    </div>,
            width:'450px',
            align:'center'
        },
        {
            key:'dartipo',
            dataIndex: 'dartipo',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'dartipo')
                return sorterCustomize(sortedColumn, 'Tipo de archivo')
            },
            ...getColumnSearchProps('dartipo', 'tipo de archivo'),
            sorter: (a, b) => {
                return a.dartipo != null ? a.dartipo.localeCompare(b.dartipo) : false
            },
            showSorterTooltip: false,
            render: (_, record) => <div 
                title={record.dartipo}>{record.dartipo}</div>,
            align:'center',
        },
        {
            key:'usuusuario',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'usuusuario')
                return sorterCustomize(sortedColumn, 'Usuario')
            },
            dataIndex: 'usuusuario',
            ...getColumnSearchProps('usuusuario', 'usuario'),
            sorter: (a, b) => {
                return a.usuusuario ? a.usuusuario.localeCompare(b.usuusuario) : false
            },
            showSorterTooltip: false,
            render: (_, record) => 
                <div style={{ width:'230px'}} title={record.usuusuario}>
                    <span style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{record.usuusuario}</span>
                </div>,
            align:'center',
            width:'230px'
        },
        {
            key:'created_at',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'created_at')
                return sorterCustomize(sortedColumn, 'Fecha')
            },
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha'),
            sorter: (a, b) => {
                return a.created_at ? a.created_at.localeCompare(b.created_at) : false
            },
            showSorterTooltip: false,
            render: (_, record) => {
                const originalDate = record.created_at
                const formattedDateTime = format(new Date(originalDate), 'dd-MM-yyyy HH:mm')

                return (
                    <div 
                        title={formattedDateTime}
                    >
                        {formattedDateTime}
                    </div>
                )
            },
            align:'center',
        },
        {
            title: 'Acción',
            dataIndex: 'accion',
            render: (_, record) => 
                <Popconfirm
                    title='Eliminar archivo'
                    description='Está seguro de eliminar el registro del archivo?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => dispatch(DeleteFileDescargasArchivosReducer(record.darid))}
                >
                    <DeleteOutlined
                        title='Eliminar'
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>,
            align:'center',
        },
    ]

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                type='primary' size='small' ghost 
                                onClick={() => navigate(-1)} 
                                className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <Title style={{width:'100%'}} className="Title-Module">
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    Descarga Archivos
                                </div>
                            </Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display:'flex', justifyContent: 'space-between', marginTop:'10px'}}>
                        <div style={{display:'flex', marginBottom:'10px'}}>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> ObtenerDescargasArchivos()}    
                            >
                                <ReloadOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Recargar
                            </Button>
                            <Space>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por Tipo de archivo"
                                    onChange={(e) => dispatch(FiltrarTiposArchivoDescargasArchivosReducer(e ? e : ''))}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    size='small'
                                    style={{width: '200px'}}
                                    options={
                                        rex_data_tipos_archivos_descargas_archivos.map(des => {
                                            return {
                                                value: des,
                                                label: des,
                                            }
                                        })
                                    }
                                />
                            </Space>
                        </div>
                        { rex_loading_data_carga_archivos && rex_data_descargas_archivos_copia == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_descargas_archivos_copia.length}
                                    pageSize={15}
                                    onChange={(page, pageSize) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_descargas_archivos_copia.length)} cargas</div>  
                            </div>
                        }
                        
                    </div>
                    { rex_loading_data_carga_archivos && rex_data_descargas_archivos_copia == 0
                     ? <div style={{marginTop:'10px'}}>
                            <Skeleton/>
                            <Skeleton/>
                        </div>
                     :  <Table
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 15,
                                current: pageTable,
                            }}
                            columns={columns}
                            dataSource={rex_data_descargas_archivos_copia}
                            className="Tabla-Carga-Archivos"
                            size='small'
                            scroll={{
                                x: '1000px',
                            }}
                        /> 
                    }
                </Card>
            </Card>
        </div>
    )
}

export default DescargasArchivos