import config from '../../../../config'
import {
    CARGANDO_DATA_DISTRIBUIDORAS,
    OBTENER_DATA_DISTRIBUIDORAS,
    GET_DATA_CONTROL_DISTRIBUTORS,
} from '../../../../Constans/Administracion/Distribuidoras/Distribuidoras'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerDataDistribuidorasReducer = () => async ( dispatch ) => {

    dispatch({
        type : CARGANDO_DATA_DISTRIBUIDORAS,
        payload : true
    })

    await fetch(config.api_url+'administration/get-distributors',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_DISTRIBUIDORAS,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_DISTRIBUIDORAS,
        payload : false
    })
}

export const GetDataControlDistributorsReducer = () => async (dispatch) => {
    let response = true
    await fetch(config.api_url+'administration/get-control-distributors',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_dist = data.data
            data_dist.map((dist, index) => {
                data_dist[index]["index"] = index + 1
            })

            dispatch({
                type : GET_DATA_CONTROL_DISTRIBUTORS,
                payload : data_dist
            })
        }else{
            response = false
            notificacionServidor('error', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const ChangeDataControlDistributorsReducer = (id, state) => async (dispatch, getState) => {
    const control_distributors = getState().distribuidoras.rex_data_control_distributors

    control_distributors.map((dist, index) => {
        if(dist.id == id) control_distributors[index]["type_client"] = state
    })

    dispatch({
        type : GET_DATA_CONTROL_DISTRIBUTORS,
        payload : control_distributors
    })

    let response = true
    await fetch(config.api_url+'administration/update-control-distributors',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                id_control_dist: id,
                state_control_dist: state,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            response = false
            notificacionServidor('error', data.message)

            control_distributors.map((dist, index) => {
                if(dist.id == id) control_distributors[index]["type_client"] = !state
            })
            dispatch({
                type : GET_DATA_CONTROL_DISTRIBUTORS,
                payload : control_distributors
            })
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}