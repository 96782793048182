import { 
    GET_DATA_ADMIN_PLATFORM,
    LOADING_DATA_ADMIN_PLATFORM,
} from "../../../../Constans/Administracion/AdminPlatform/AdminPlatform"

const INIT_STATE = {
    rex_data_admin_platform     : {
        main_text: '',
        secondary_text: ''
    },
    rex_loading_admin_platform  : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_ADMIN_PLATFORM: {
            return {
                ...state,
                rex_data_admin_platform : action.payload
            }
        }
        case LOADING_DATA_ADMIN_PLATFORM: {
            return {
                ...state,
                rex_loading_admin_platform : action.payload
            }
        }
        default:
            return state
    }
}