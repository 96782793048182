import { 
    OBTENER_DATA_MAESTRA_PRODUCTOS_GROW,
    OBTENER_DATA_MAESTRA_CLIENTES_GROW,
    CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
    CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
    FECHA_ACTUALIZACION_MAESTRA_CLIENTES_GROW,
    FECHA_ACTUALIZACION_MAESTRA_PRODUCTOS_GROW
} from "../../../../Constans/Administracion/MaestraGrow/MaestraGrow";


const INIT_STATE = {
    rex_data_maestra_productos_grow : [],
    rex_data_maestra_clientes_grow  : [],
    rex_cargando_maestra_productos_grow : true,
    rex_cargando_maestra_clientes_grow           : true,
    rex_feha_actualizacion_maestra_clientes_grow : null,
    rex_feha_actualizacion_maestra_productos_grow : null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FECHA_ACTUALIZACION_MAESTRA_PRODUCTOS_GROW: {
            return {
                ...state,
                rex_feha_actualizacion_maestra_productos_grow : action.payload
            }
        }
        case FECHA_ACTUALIZACION_MAESTRA_CLIENTES_GROW: {
            return {
                ...state,
                rex_feha_actualizacion_maestra_clientes_grow : action.payload
            }
        }
        case CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW: {
            return {
                ...state,
                rex_cargando_maestra_productos_grow : action.payload
            }
        }
        case CARGANDO_DATA_MAESTRA_CLIENTES_GROW: {
            return {
                ...state,
                rex_cargando_maestra_clientes_grow : action.payload
            }
        }
        case OBTENER_DATA_MAESTRA_PRODUCTOS_GROW: {
            return {
                ...state,
                rex_data_maestra_productos_grow : action.payload
            }
        }
        case OBTENER_DATA_MAESTRA_CLIENTES_GROW: {
            return {
                ...state,
                rex_data_maestra_clientes_grow : action.payload
            }
        }
        default:
            return state;
    }
}
