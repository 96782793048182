import React, {useEffect, useState} from 'react'
import { Modal, Table, Input, Select } from 'antd'
import {
    MenuOutlined,
} from '@ant-design/icons'
import { AsignarFiltrosMaestraProductosReducer, FiltrarMaestraProductosReducer } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import { useDispatch, useSelector } from 'react-redux'
import { SelectSkuReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'

const ModalSkuNoHomologaciones = (props) => {

    useEffect(() => {
        dispatch(AsignarFiltrosMaestraProductosReducer())
    },[])

    const aceptar = props.aceptar
    const abrirModalSku = props.abrirModalSku
    const setAbrirModalSku = props.setAbrirModalSku
    const rex_data_maestra_productos = props.rex_data_maestra_productos
    const idRow = props.idRow
    const id_producto_so = props.id_producto_so
    const producto_so_select = props.producto_so_select
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const dispatch = useDispatch()

    const [ txtSegmento, setTxtSegmento ] = useState('')
    const [ txtNombre, setTxtNombre ] = useState('')
    const [ txtCategoria, setTxtCategoria ] = useState('')

    const {
        rex_filtro_data_maestra_codigo,
        rex_filtro_data_maestra_nombre,
        rex_filtro_data_maestra_categoria,
    } = useSelector(({maestraProductos}) => maestraProductos)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<MenuOutlined />),
        onFilter: (value, record) => {
            const text = record[dataIndex] || ''
            return text.toString().toLowerCase().includes(value.toLowerCase())
        }
    })

    const columns = [
        {
            title: 'Código material',
            dataIndex: 'codigo_material',
            ...getColumnSearchProps('codigo_material', 'Código material'),
            sorter: (a, b) => a.codigo_material ? a.codigo_material.localeCompare(b.codigo_material) : false,
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material ? codigo_material : ''}>{codigo_material ? codigo_material : ''}</div>,
            width: '120px'
        },
        {
            title: 'Material',
            dataIndex: 'material',
            ...getColumnSearchProps('material', 'Material'),
            sorter: (a, b) => a.material ? a.material.localeCompare(b.material) : false,
            showSorterTooltip: false,
            render: (material) => <div title={material ? material : ''}>{material ? material : ''}</div>,
            width: '120px'
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            ...getColumnSearchProps('categoria', 'Categoría'),
            sorter: (a, b) => a.categoria ? a.categoria.localeCompare(b.categoria) : false,
            showSorterTooltip: false,
            render: (categoria) => <div title={categoria ? categoria : ''}>{categoria ? categoria : ''}</div>,
            width: '120px'
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'subcategoria',
            ...getColumnSearchProps('subcategoria', 'Sub Categoría'),
            sorter: (a, b) => a.subcategoria ? a.subcategoria.localeCompare(b.subcategoria) : false,
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria ? subcategoria : ''}>{subcategoria ? subcategoria : ''}</div>,
            width: '120px'
        },
        {
            title: 'Formato',
            dataIndex: 'formato',
            ...getColumnSearchProps('formato', 'Formato'),
            sorter: (a, b) => a.formato ? a.formato.localeCompare(b.formato): false,
            showSorterTooltip: false,
            render: (formato) => <div title={formato ? formato : ''}>{formato ? formato : ''}</div>,
            width: '120px'
        },
        {
            title: 'Codigo EAN',
            dataIndex: 'codigo_ean',
            ...getColumnSearchProps('codigo_ean', 'Codigo EAN'),
            sorter: (a, b) => a.codigo_ean ? a.codigo_ean.localeCompare(b.codigo_ean) : false,
            showSorterTooltip: false,
            render: (codigo_ean) => <div title={codigo_ean ? codigo_ean : '' }>{codigo_ean ? codigo_ean : ''}</div>,
            width: '120px'
        },
        {
            title: 'Attr 1',
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1', 'Attr 1'),
            sorter: (a, b) => a.attr_1 ? a.attr_1.localeCompare(b.attr_1) : false,
            showSorterTooltip: false,
            render: (attr_1) => <div title={attr_1 ? attr_1 : ''}>{attr_1 ? attr_1 : ''}</div>,
            width: '120px'
        },
        {
            title: 'Attr 2',
            dataIndex: 'attr_2',
            ...getColumnSearchProps('attr_2', 'Attr 2'),
            sorter: (a, b) => a.attr_2 ? a.attr_2.localeCompare(b.attr_2) : false,
            showSorterTooltip: false,
            render: (attr_2) => <div title={attr_2 ? attr_2 : ''}>{attr_2 ? attr_2 : ''}</div>,
            width: '120px'
        },
        {
            title: 'Attr 3',
            dataIndex: 'attr_3',
            ...getColumnSearchProps('attr_3', 'Attr 3'),
            sorter: (a, b) => a.attr_3 ? a.attr_3.localeCompare(b.attr_3) : false,
            showSorterTooltip: false,
            render: (attr_3) => <div title={attr_3 ? attr_3 : ''}>{attr_3 ? attr_3 : ''}</div>,
            width: '120px'
        },
        {
            title: 'Attr 4',
            dataIndex: 'attr_4',
            ...getColumnSearchProps('attr_4', 'Attr 4'),
            sorter: (a, b) => a.attr_4 ? a.attr_4.localeCompare(b.attr_4) : false,
            showSorterTooltip: false,
            render: (attr_4) => <div title={attr_4 ? attr_4 : ''}>{attr_4 ? attr_4 : ''}</div>,
            width: '120px'
        },
        {
            title: 'Attr 5',
            dataIndex: 'attr_5',
            ...getColumnSearchProps('attr_5', 'Attr 5'),
            sorter: (a, b) => a.attr_5 ? a.attr_5.localeCompare(b.attr_5) : false,
            showSorterTooltip: false,
            render: (attr_5) => <div title={attr_5 ? attr_5 : ''}>{attr_5 ? attr_5 : ''}</div>,
            width: '120px'
        },
        {
            title: 'Attr 6',
            dataIndex: 'attr_6',
            ...getColumnSearchProps('attr_6', 'Attr 6'),
            sorter: (a, b) => a.attr_6 ? a.attr_6.localeCompare(b.attr_6) : false,
            showSorterTooltip: false,
            render: (attr_6) => <div title={attr_6 ? attr_6 : ''}>{attr_6 ? attr_6 : ''}</div>,
            width: '120px'
        },

        {
            title: 'EQ CJS',
            dataIndex: 'eq_cjs',
            ...getColumnSearchProps('eq_cjs', 'EQ CJS'),
            sorter: (a, b) => a.eq_cjs ? a.eq_cjs - b.eq_cjs : false,
            showSorterTooltip: false,
            render: (eq_cjs) => <div title={eq_cjs ? eq_cjs : ''}>{eq_cjs ? eq_cjs : ''}</div>,
            width: '120px'
        },
        {
            title: 'EQ TON',
            dataIndex: 'eq_ton',
            ...getColumnSearchProps('eq_ton', 'EQ TON'),
            sorter: (a, b) => a.eq_ton ? a.eq_ton - b.eq_ton : false,
            showSorterTooltip: false,
            render: (eq_ton) => <div title={eq_ton ? eq_ton : ''}>{eq_ton ? eq_ton : ''}</div>,
            width: '120px'
        },
        {
            title: 'EQ GSU',
            dataIndex: 'eq_gsu',
            ...getColumnSearchProps('eq_gsu', 'EQ GSU'),
            sorter: (a, b) => a.eq_gsu ? a.eq_gsu - b.eq_gsu : false,
            showSorterTooltip: false,
            render: (eq_gsu) => <div title={eq_gsu ? eq_gsu : ''}>{eq_gsu ? eq_gsu : ''}</div>,
            width: '120px'
        },

        {
            title: 'EQ SOLES NIV',
            dataIndex: 'eq_soles_niv',
            ...getColumnSearchProps('eq_soles_niv', 'EQ SOLES NIV'),
            sorter: (a, b) => a.eq_soles_niv ? a.eq_soles_niv - b.eq_soles_niv : false,
            showSorterTooltip: false,
            render: (eq_soles_niv) => <div title={eq_soles_niv ? eq_soles_niv : ''}>{eq_soles_niv ? eq_soles_niv : ''}</div>,
            width: '120px'
        },
        {
            title: 'EQ SOLES NET',
            dataIndex: 'eq_soles_net',
            ...getColumnSearchProps('eq_soles_net', 'EQ SOLES NET'),
            sorter: (a, b) => a.eq_soles_net ? a.eq_soles_net - b.eq_soles_net : false,
            showSorterTooltip: false,
            render: (eq_soles_net) => <div title={eq_soles_net ? eq_soles_net : ''}>{eq_soles_net ? eq_soles_net : ''}</div>,
            width: '120px'
        },
        {
            title: 'EQ SOLES INVOICE',
            dataIndex: 'eq_soles_invoice',
            ...getColumnSearchProps('eq_soles_invoice', 'EQ SOLES INVOICE'),
            sorter: (a, b) => a.eq_soles_invoice ? a.eq_soles_invoice - b.eq_soles_invoice : false,
            showSorterTooltip: false,
            render: (eq_soles_invoice) => <div title={eq_soles_invoice ? eq_soles_invoice : ''}>{eq_soles_invoice ? eq_soles_invoice : ''}</div>,
            width: '120px'
        },

        {
            title: 'EQ SOLES DSCTO',
            dataIndex: 'eq_soles_dscto',
            ...getColumnSearchProps('eq_soles_dscto', 'EQ SOLES DSCTO'),
            sorter: (a, b) => a.eq_soles_dscto ? a.eq_soles_dscto - b.eq_soles_dscto : false,
            showSorterTooltip: false,
            render: (eq_soles_dscto) => <div title={eq_soles_dscto ? eq_soles_dscto : ''}>{eq_soles_dscto ? eq_soles_dscto : ''}</div>,
            width: '120px'
        },
    ]

    const seleccionarFila = (record) => {
        return {
            onClick: event => {
                setSelectedRowKeys([record.key])
                dispatch(SelectSkuReducer(idRow, id_producto_so, producto_so_select, record))
            }
        }
    }

    const rowClassName = (record) => {
        return selectedRowKeys.includes(record.key) ? 'Row-Active' : ''
    }

    return (
        <Modal 
            title="Maestra de Productos"
            open={idRow == abrirModalSku ? true : false} 
            onCancel={() => {
                setAbrirModalSku(null)
                dispatch(FiltrarMaestraProductosReducer('','',''))
                setTxtNombre('')
                setTxtSegmento('')
                setTxtCategoria('')
            }}
            onOk={() => {
                aceptar()
                setAbrirModalSku(null)
                dispatch(FiltrarMaestraProductosReducer('','',''))
                setTxtNombre('')
                setTxtSegmento('')
                setTxtCategoria('')
            }}
            centered
            closable={false}
            width={1000}
        >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                <Select
                    showSearch
                    placeholder="Nombre"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtNombre('')
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, '', txtCategoria))
                    }}
                    onSelect={ (value) => {
                        setTxtNombre(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, value, txtCategoria))
                    }}
                    onSearch={(value ) => {
                        setTxtNombre(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, value, txtCategoria))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length == 0
                                ? rex_filtro_data_maestra_nombre
                                : rex_filtro_data_maestra_nombre
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.material ? ma.material.toUpperCase() == rex.value : false))}
                />
        
                <Select
                    showSearch
                    placeholder="Segmento"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtSegmento('')
                        dispatch(FiltrarMaestraProductosReducer('',txtNombre, txtCategoria))
                    }}
                    onSelect={ (value) => {
                        setTxtSegmento(value)
                        dispatch(FiltrarMaestraProductosReducer(value, txtNombre, txtCategoria))
                    }}
                    onSearch={(value ) => {
                        setTxtSegmento(value)
                        dispatch(FiltrarMaestraProductosReducer(value, txtNombre, txtCategoria))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length == 0
                                ? rex_filtro_data_maestra_codigo
                                : rex_filtro_data_maestra_codigo
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.codigo_material ? ma.codigo_material.toUpperCase() == rex.value : false))}
                />
                <Select
                    showSearch
                    placeholder="Categoria"
                    optionFilterProp="children"
                    allowClear={true}
                    onClear={() => {
                        setTxtCategoria('')
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, ''))
                    }}
                    onSelect={ (value) => {
                        setTxtCategoria(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, value))
                    }}
                    onSearch={(value ) => {
                        setTxtCategoria(value)
                        dispatch(FiltrarMaestraProductosReducer(txtSegmento, txtNombre, value))
                    }}
                    style={{marginLeft: '4px', width:'150px'}}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    options={txtNombre.length + txtSegmento.length + txtCategoria.length == 0
                                ? rex_filtro_data_maestra_categoria
                                : rex_filtro_data_maestra_categoria
                                    .filter(rex => rex_data_maestra_productos
                                        .find((ma) => ma.categoria ? ma.categoria.toUpperCase() == rex.value : false))}
                />
                </div>

                <div style={{textAlign: 'end', marginBottom: '3px'}}>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos.length)} registros</div>
            </div>
            <Table 
                pagination={{
                    position: ["topRight", "none"],
                    pageSize: 15
                }}
                scroll={{
                    x: '1500px',
                    y: '430px',
                }}
                onRow={seleccionarFila}
                columns={columns} 
                dataSource={rex_data_maestra_productos} 
                className="Table-Home Modal-No-Homologados"
                rowKey="key"
                rowClassName={rowClassName}
                size='small'
            />
        </Modal>
    )
}

export default ModalSkuNoHomologaciones