import config from '../../../config'
import {
    DATA_USER_LOGIN,
    DATA_USER_PERMISSIONS
} from '../../../Constans/Login/Login'
import { notification } from 'antd'

export const ValidateUser = (token) => async (dispatch, getState) => {
    
    await fetch(config.api+'validation-user',
    {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
            'token'        : token
        }
    })
    .then(res => res.json())
    .then(data => {
        if(!data.response){
            localStorage.clear()
            notification['warning']({
                message: 'Advertencia',
                description: data.message,
            })
            dispatch({
                type: DATA_USER_LOGIN,
                payload: []
            })
        }else{
            localStorage.setItem('tpuprivilegio', data.usu.tputiposusuarios.tpuprivilegio)
            dispatch({
                type: DATA_USER_LOGIN,
                payload: data.usu
            })
            dispatch({
                type: DATA_USER_PERMISSIONS,
                payload: data.usu.tputiposusuarios.tuptiposusuariospermisos
            })

        }
    })
    .catch((error)=> {
        console.log(error)
    })

    return true
}