import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Button, Input, Popconfirm, Tooltip, Pagination, Skeleton, Select, Space } from 'antd'
import {
    ReloadOutlined,
    ArrowLeftOutlined,
    FilterOutlined,
    DeleteOutlined,
    CheckCircleTwoTone,
    NotificationOutlined,
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons'
import config from '../../../config'
import '../../../Styles/Components/Administracion/Administrador/Usuarios.css'
import '../../../Styles/Components/Administracion/Administrador/CargaArchivos.css'
import { 
    EliminarArchivoCargaArchivosReducer,
    ObtenerDataCargaArchivosReducer,
    ProcesarArchivoCargaArchivosReducer,
    FiltrarTiposArchivoCargaArchivosReducer,
} from '../../../Redux/Actions/Administracion/Administrador/CargaArchivos'
import ModalNotificacionesError from '../../../Components/Administracion/Administrador/CargaArchivos/ModalNotificacionesError'
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom'

const CargaArchivos = (props) => {

    const collapsed = props.collapsed
    const { Title } = Typography
    const [ openModal, setOpenModal ] = useState(false)
    const [ notificaciones, setNotificaciones ] = useState(null)
    const [ pageTable, setPageTable ] = useState(1)
    const [ filterType, setFilterType ] = useState('')
    const [ filterProcess, setFilterProcess ] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const {
        rex_data_carga_archivos,
        rex_cargando_data_carga_archivos,
        rex_data_carga_archivos_copia,
        rex_data_tipos_archivos_carga_archivos,
        rex_mostrar_solo_archivos_cargados_carga_archivos
    } = useSelector(({cargaArchivos}) => cargaArchivos)
    

    const ObtenerCargaArchivos = async () => {
        await dispatch(ObtenerDataCargaArchivosReducer())
        await dispatch(FiltrarTiposArchivoCargaArchivosReducer(filterType, filterProcess))
    }

    useEffect(() => {
        if(rex_data_carga_archivos.length == 0){
            dispatch(ObtenerDataCargaArchivosReducer())
        }
    },[])

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterOutlined 
                style={{color:'white'}}
            />
        ),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split("-").reverse().join('-')
                return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(fecha.toLowerCase()) : false
            }else if(dataIndex == 'carestadoprocesado'){
                const estado_proceso = [
                    {name: 'Cargado, Procesado', carfechaprocesado: true, carexito: true},
                    {name: 'Cargado, No Procesado', carfechaprocesado: null, carexito: true},
                    {name: 'Cargado parcialmente, Procesado', carfechaprocesado: true, carexito: false},
                    {name: 'Cargado parcialmente, No Procesado', carfechaprocesado: null, carexito: false},
                ]
                const carfechaprocesado = record.carfechaprocesado ? true : null
                const carexito = record.carexito

                const filter_est = estado_proceso.filter(ep => ep.name.toLowerCase().includes(value.toLowerCase()))
                if(filter_est.length > 0){
                    const find_est_pro = filter_est.find(fe => fe.carfechaprocesado == carfechaprocesado && fe.carexito == carexito)

                    return find_est_pro ? true : false
                }else{
                    return false
                }
            }else{
                return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
            }
        }
    })

    const sorterCustomize = ( sortedColumn, nameColum ) => {
        return (
            <div className='Container-Sorter-Column-Carga-Archivos'>
                <span>{nameColum}</span>
                <div style={{marginLeft:'5px'}}>
                    {sortedColumn ? (
                        sortedColumn.order == 'ascend' 
                        ? ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined /><CaretDownOutlined style={{color:'black', marginTop:'-2px'}}/></div> )
                        : sortedColumn.order == 'descend'
                            ? ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined style={{color:'black'}}/><CaretDownOutlined style={{ marginTop:'-2px'}}/></div> )
                            : ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined style={{color:'black'}} /><CaretDownOutlined style={{color:'black', marginTop:'-2px'}}/></div> )
                    ): ( <div style={{display:'flex', flexDirection:'column'}}><CaretUpOutlined style={{color:'black'}} /><CaretDownOutlined style={{color:'black',  marginTop:'-2px'}}/></div> )}
                </div>
            </div>
        )
    }

    const columns = [
        {
            key: 'index',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'index')
                return sorterCustomize(sortedColumn,'N°')
            },
            dataIndex: 'index',
            sorter: (a, b) => a.index - b.index,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.index}</div>,
            align:'center',
        },
        {
            key:'carnombre',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'carnombre')
                return sorterCustomize(sortedColumn, 'Nombre')
            },
            dataIndex: 'carnombre',
            ...getColumnSearchProps('carnombre', 'nombre archivo'),
            sorter: (a, b) => a.carnombre? a.carnombre.localeCompare(b.carnombre) : false,
            showSorterTooltip: false,
            render: (_, record) => 
                    <div style={{width:'150px'}}>
                        <a style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}
                            href={`${config.api_url}carga-archivos/generar-descarga?token=${record.cartoken}`}
                        >
                            {record.carnombre}
                        </a>
                    </div>,
            width:'150px',
            align:'center'
        },
        {
            key:'cartipo',
            dataIndex: 'cartipo',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'cartipo')
                return sorterCustomize(sortedColumn, 'Tipo de archivo')
            },
            ...getColumnSearchProps('cartipo', 'tipo de archivo'),
            sorter: (a, b) => {
                return a.cartipo != null ? a.cartipo.localeCompare(b.cartipo) : false
            },
            showSorterTooltip: false,
            render: (_, record) => <div 
                title={record.cartipo}>{record.cartipo}</div>,
            align:'center',
        },
        {
            key:'usuusuario',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'usuusuario')
                return sorterCustomize(sortedColumn, 'Usuario')
            },
            dataIndex: 'usuusuario',
            ...getColumnSearchProps('usuusuario', 'usuario'),
            sorter: (a, b) => {
                return a.usuusuario ? a.usuusuario.localeCompare(b.usuusuario) : false
            },
            showSorterTooltip: false,
            render: (_, record) => 
                <div style={{ width:'220px'}} title={record.usuusuario}>
                    <span style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{record.usuusuario}</span>
                </div>,
            align:'center',
            width:'220px'
        },
        {
            key:'created_at',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'created_at')
                return sorterCustomize(sortedColumn, 'Fecha')
            },
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha'),
            sorter: (a, b) => {
                return a.created_at ? a.created_at.localeCompare(b.created_at) : false
            },
            showSorterTooltip: false,
            render: (_, record) => {
                const originalDate = record.created_at
                const formattedDateTime = format(new Date(originalDate), 'dd-MM-yyyy HH:mm');

                return (
                    <div 
                        title={formattedDateTime}
                    >
                        {formattedDateTime}
                    </div>
                )
            },
            align:'center',
        },
        {
            key:'carnotificaciones',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'carnotificaciones')
                return sorterCustomize(sortedColumn, 'Notificacion')
            },
            dataIndex: 'carnotificaciones',
            sorter: (a, b) => a.carexito - b.carexito,
            showSorterTooltip: false,
            render: (_, record) => <div 
                title={record.carexito ? record.carnotificaciones: ''}>             
                {
                record.carnotificaciones
                ?   <Tooltip title="Ver Notificaciones">
                    <a
                        style={{textDecoration:'underline'}}
                        onClick={() => {
                            let message_notification = record.carnotificaciones.includes('notificaciones') ? JSON.parse(record.carnotificaciones) : record.carnotificaciones
                            setNotificaciones(message_notification)
                            setOpenModal(true)
                        }}
                    ><NotificationOutlined /></a>
                    </Tooltip>
                : <Tooltip title="Sin Notificaciones"><NotificationOutlined /></Tooltip>
                }
            </div>,
            align:'center'
        },
        // {
        //     key:'carplataforma',
        //     title: ({sortColumns}) => {
        //         const sortedColumn = sortColumns?.find(({column}) => column.key === 'carplataforma')
        //         return sorterCustomize(sortedColumn, 'Plataforma')
        //     },
        //     dataIndex: 'carplataforma',
        //     ...getColumnSearchProps('carplataforma', 'plataforma'),
        //     sorter: (a, b) => a.carplataforma ? a.carplataforma.localeCompare(b.carplataforma) : false,
        //     showSorterTooltip: false,
        //     render: (_, record) => 
        //         <div title={record.carplataforma ? record.carplataforma : ''}  
        //         style={{width:'110px', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}
        //         >
        //             {record.carplataforma ? record.carplataforma : ''}
        //         </div>,
        //     align:'center',
        //     width:'110px'
        // },
        // {
        //     key:'carconexion',
        //     title: ({sortColumns}) => {
        //         const sortedColumn = sortColumns?.find(({column}) => column.key === 'carconexion')
        //         return sorterCustomize(sortedColumn, 'Conexión')
        //     },
        //     dataIndex: 'carconexion',
        //     ...getColumnSearchProps('carconexion', 'conexión'),
        //     sorter: (a, b) => {
        //         const aconexion = a.carconexion ? a.carconexion : ''
        //         const bconexion = b.carconexion ? b.carconexion : ''
        //         return aconexion.localeCompare(bconexion)
        //     },
        //     showSorterTooltip: false,
        //     render: (_, record) => 
        //         <div title={record.carconexion ? record.carconexion : ''} 
        //         style={{width:'100px'}}
        //         >
        //             <span className='Name-Plataform' style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{record.carconexion ? record.carconexion : ''}</span>
        //         </div>,
        //     align:'center',
        //     width:'100px'
        // },
        {
            key:'carprocesado',
            title: ({sortColumns}) => {
                const sortedColumn = sortColumns?.find(({column}) => column.key === 'carprocesado')
                return sorterCustomize(sortedColumn, 'Procesado')
            },
            dataIndex: 'carestadoprocesado',
            ...getColumnSearchProps('carestadoprocesado', 'estado procesado'),
            sorter: (a, b) => {
                return a.carestadoprocesado ? a.carestadoprocesado.localeCompare(b.carestadoprocesado) : false
            },
            showSorterTooltip: false,
            render: (_, record) => 
                <div>
                    {/* <div className='Cell-Action-Process-File'>
                        <Tooltip placement='left' 
                            overlayClassName='Tooltip-Carga-Archivos-Table-Carga-Archivos'
                            title={'Procesar archivo'}
                        >
                            <CheckCircleTwoTone
                                onClick={() => dispatch(ProcesarArchivoCargaArchivosReducer(record.carid))}
                                style={{cursor:'pointer', fontSize:'14px'}} twoToneColor="#52c41a"
                            />
                        </Tooltip>
                    </div> */}
                    {
                        record.carfechaprocesado == null
                        ?   record.carexito
                            ?   <span className='Cell-Info-No-Process-File'>Cargado, No procesado</span>
                            :   <span className='Cell-Info-No-Process-File'>Cargado parcialmente, No procesado</span>
                        :   record.carexito
                            ?   <span className='Cell-Info-Process-File'>Cargado, Procesado</span>
                            :   <span className='Cell-Info-Process-File'>Cargado parcialmente, Procesado</span>
                    }
                </div>,
            align:'center',
        },
        {
            title: 'Acción',
            dataIndex: 'accion',
            render: (_, record) => 
                <div>
                    {
                        record.carfechaprocesado == null
                        ?   <Popconfirm
                                title='Eliminar archivo'
                                description='Está seguro de eliminar el registro del archivo?'
                                okText='Confirmar'
                                cancelText='Cancelar'
                                placement="bottomRight"
                                onConfirm={() => dispatch(EliminarArchivoCargaArchivosReducer(record.carid))}
                            >
                                <DeleteOutlined
                                    title='Eliminar'
                                    style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                                />
                            </Popconfirm>
                        :   null
                    }
                </div>,
            align:'center',
        },
    ]

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                type='primary' size='small' ghost 
                                onClick={() => navigate(-1)} 
                                className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <Title style={{width:'100%'}} className="Title-Module">
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <span>Carga Archivos</span>
                                    <span>Mostrando todos los archivos</span> 
                                    {/* { rex_mostrar_solo_archivos_cargados_carga_archivos
                                    ? <span onClick={() => {
                                        dispatch(FiltrarTiposArchivoCargaArchivosReducer(filterType,filterProcess,true, false))
                                    }}>Mostrando archivos subidos correctamente</span> 
                                    : <span onClick={() => {
                                        dispatch(FiltrarTiposArchivoCargaArchivosReducer(filterType, filterProcess, true, true))
                                    }}>Mostrando todos los archivos</span>
                                    } */}
                                </div>
                            </Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display:'flex', justifyContent: 'space-between', marginTop:'10px'}}>
                        <div style={{display:'flex', marginBottom:'10px'}}>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> {
                                    ObtenerCargaArchivos()
                                }}    
                            >
                                <ReloadOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Recargar
                            </Button>
                            <Space>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por procesamiento"
                                    onChange={(e) => {
                                        let carprocess_value
                                        if(e == undefined){
                                            carprocess_value = ''
                                        }else{
                                            carprocess_value = e
                                        }
                                        setFilterProcess(carprocess_value)
                                        dispatch(FiltrarTiposArchivoCargaArchivosReducer(filterType, carprocess_value))
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    value={filterProcess? filterProcess : null}
                                    size='small'
                                    style={{width: '200px'}}
                                    options={[
                                        {value: 'Cargado, Procesado', label: 'Cargado, Procesado'},
                                        {value: 'Cargado, No Procesado', label: 'Cargado, No Procesado'},
                                        {value: 'Cargado parcialmente, Procesado', label: 'Cargado parcialmente, Procesado'},
                                        {value: 'Cargado parcialmente, No Procesado', label: 'Cargado parcialmente, No Procesado'},
                                    ]}
                                />
                                <span></span>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por Tipo de archivo"
                                    onChange={(e) => {
                                        const cartipo_value = e ? e : ''
                                        setFilterType(e)
                                        dispatch(FiltrarTiposArchivoCargaArchivosReducer(cartipo_value,filterProcess))
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    size='small'
                                    value={filterType? filterType : null}
                                    style={{width: '200px'}}
                                    options={
                                        rex_data_tipos_archivos_carga_archivos.map(cartipo => {
                                            return {
                                                value: cartipo,
                                                label: cartipo,
                                            }
                                        })
                                    }
                                />
                            </Space>
                        </div>
                        { rex_cargando_data_carga_archivos && rex_data_carga_archivos_copia == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_carga_archivos_copia.length}
                                    pageSize={15}
                                    onChange={(page, pageSize) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_carga_archivos_copia.length)} cargas</div>  
                            </div>
                        }
                        
                    </div>
                    { rex_cargando_data_carga_archivos && rex_data_carga_archivos_copia == 0
                     ? <div style={{marginTop:'10px'}}>
                            <Skeleton/>
                            <Skeleton/>
                        </div>
                     :  <Table
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 15,
                                current: pageTable,
                            }}
                            columns={columns}
                            dataSource={rex_data_carga_archivos_copia}
                            className="Tabla-Carga-Archivos"
                            size='small'
                            scroll={{
                                x: '1000px',
                            }}
                            // rowClassName={(record, index) => record.carfechaprocesado ? 'Row-File-Process': 'Row-File-No-Process' } 
                        /> 
                    }
                </Card>
            </Card>
            <ModalNotificacionesError
                isModalOpen={openModal}
                setIsModalOpen={setOpenModal}
                data={notificaciones}
            />
        </div>
    )
}

export default CargaArchivos