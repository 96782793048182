import {
    GET_DATA_AUDITS,
    GET_DATA_AUDITS_CLONE,
    GET_DATA_AUDITS_MODULE,
    LOADING_DATA_AUDITS,
    FILTER_TEXT_AUDIT,
    FILTER_ORDER_AUDIT,
    PAGINATE_AUDIT,
    TOTAL_REGISTER_AUDIT,
} from "../../../Constans/Audits/Audits"

const INIT_STATE = {
    rex_data_audits : [],
    rex_data_audits_clone : [],
    rex_data_audits_module : [],
    rex_loading_data_audits : true,
    rex_filter_text_audit : {},
    rex_filter_order_audit : { column: null, orden: null },
    rex_paginate_audit : 1,
    rex_total_register_audit : 0,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOADING_DATA_AUDITS: {
            return {
                ...state,
                rex_loading_data_audits : action.payload
            }
        }
        case GET_DATA_AUDITS: {
            return {
                ...state,
                rex_data_audits : action.payload
            }
        }
        case GET_DATA_AUDITS_CLONE: {
            return {
                ...state,
                rex_data_audits_clone : action.payload
            }
        }
        case GET_DATA_AUDITS_MODULE: {
            return {
                ...state,
                rex_data_audits_module : action.payload
            }
        }
        case FILTER_TEXT_AUDIT: {
            return {
                ...state,
                rex_filter_text_audit : action.payload
            }
        }
        case FILTER_ORDER_AUDIT: {
            return {
                ...state,
                rex_filter_order_audit : action.payload
            }
        }
        case PAGINATE_AUDIT: {
            return {
                ...state,
                rex_paginate_audit : action.payload
            }
        }
        case TOTAL_REGISTER_AUDIT: {
            return {
                ...state,
                rex_total_register_audit : action.payload
            }
        }
        default:
            return state;
    }
}
