import {
    OBTENER_DATA_NO_HOMOLOGADOS,
    OBTENER_DATA_MASTER_PRODUCTOS,
    SELECT_PRODUCT_NO_HOMOLOGACION,
    CARGANDO_OBTENER_DATA_NO_HOMOLOGADOS,
    FILTRO_INPUT_DATA_NO_HOMOLOGADOS,
    FILTRO_ORDEN_DATA_NO_HOMOLOGADOS,
    PAGINA_TABLA_DATA_NO_HOMOLOGADOS,
    FILTRO_TIPOS_CONEXIONES_NO_HOMOLOGADOS
} from '../../../../Constans/Homologaciones/NoHomologados/NoHomologados'

const INIT_STATE = {
    rex_data_no_homologados                 : [],
    rex_data_master_productos               : [],
    rex_select_product_no_homologacion      : {},
    rex_cargando_data_no_homologados        : false,
    rex_filtro_input_data_no_homologados    : {},
    rex_filtro_orden_data_no_homologados    : { orden:'desc', column: 's_ytd' },
    rex_pagina_tabla_data_no_homologados    : 1,
    rex_filtro_conexiones_no_homologados    : [],
    rex_filtro_responsable_no_homologados   : '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FILTRO_TIPOS_CONEXIONES_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_filtro_conexiones_no_homologados : action.payload
            }
        }
        case FILTRO_INPUT_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_filtro_input_data_no_homologados : action.payload
            }
        }
        case FILTRO_ORDEN_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_filtro_orden_data_no_homologados : action.payload
            }
        }
        case PAGINA_TABLA_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_pagina_tabla_data_no_homologados : action.payload
            }
        }
        case CARGANDO_OBTENER_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_cargando_data_no_homologados : action.payload
            }
        }
        case OBTENER_DATA_NO_HOMOLOGADOS: {
            return {
                ...state,
                rex_data_no_homologados : action.payload
            }
        }
        case OBTENER_DATA_MASTER_PRODUCTOS: {
            return {
                ...state,
                rex_data_master_productos : action.payload
            }
        }
        case SELECT_PRODUCT_NO_HOMOLOGACION: {
            return {
                ...state,
                rex_select_product_no_homologacion : action.payload
            }
        }
        default:
            return state;
    }
}
