import { 
    GET_DATA_MASTER_PRICES,
    FILTER_DATA_MASTER_PRICES,
    ORDER_DATA_MASTER_PRICES,
    PAGINATE_MASTER_PRICES,
    REGISTER_TOTAL_MASTER_PRICES,
    LOADING_DATA_MASTER_PRICES,
} from "../../../../Constans/Administracion/MasterPrices/MasterPrices"

const INIT_STATE = {
    rex_data_master_prices              : [],
    rex_filter_data_master_prices       : {},
    rex_order_data_master_prices        : { column: null, orden: null },
    rex_paginate_master_prices          : 1,
    rex_register_total_master_prices    : 0,
    rex_loading_data_master_prices      : false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_MASTER_PRICES: {
            return {
                ...state,
                rex_data_master_prices : action.payload
            }
        }
        case FILTER_DATA_MASTER_PRICES: {
            return {
                ...state,
                rex_filter_data_master_prices : action.payload
            }
        }
        case ORDER_DATA_MASTER_PRICES: {
            return {
                ...state,
                rex_order_data_master_prices : action.payload
            }
        }
        case PAGINATE_MASTER_PRICES: {
            return {
                ...state,
                rex_paginate_master_prices : action.payload
            }
        }
        case REGISTER_TOTAL_MASTER_PRICES: {
            return {
                ...state,
                rex_register_total_master_prices : action.payload
            }
        }
        case LOADING_DATA_MASTER_PRICES: {
            return {
                ...state,
                rex_loading_data_master_prices : action.payload
            }
        }
        default:
            return state
    }
}