import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Typography, Table, Button, Input, Skeleton, Row, Col, Tooltip, notification } from 'antd'
import { ChangeUnMinAssignmentProductMassive, GetDataAssignmentProductMassive, GetProductAssignmentProductMassive, LoadingAssignmentProductMassive, SelectedProductAssignmentProductMassive, UpdateAssignmentProductMassive } from '../../../Redux/Actions/Administracion/AssignmentProductMassive/AssignmentProductMassive'
import {
    LoadingOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import '../../../Styles/Routes/AssignmentProductMassive/AssignmentProductMassive.css'

const AssignmentProductMassive = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const [loadingAssignment, setLoadingAssignment] = useState(false)
    const [searchText, setSearchText] = useState(null)

    const dispatch = useDispatch()

    const {
        rex_data_assignment_product_massive,
        rex_selected_product,
        rex_un_min_product_massive,
        rex_loading_assignment_product_massive,
    } = useSelector(({assignmentProductMassive}) => assignmentProductMassive)

    useEffect(() => {
        dispatch(GetProductAssignmentProductMassive())
    }, [])

    useEffect(() => {
        const timeOutInactividad = setTimeout(() => {
            dispatch(GetDataAssignmentProductMassive(searchText))
        }, 1000)
    
        return () => clearTimeout(timeOutInactividad)
    }, [searchText])

    const selectedRow = (record) => {
        return {
            onClick: event => {
                dispatch(SelectedProductAssignmentProductMassive({codigo_material: record.codigo_material, material: record.material}))
            }
        }
    }

    const assignmentProductMassive = async () => {
        if(rex_selected_product.codigo_material && rex_selected_product.material && rex_un_min_product_massive){
            setLoadingAssignment(true)
            const response = await dispatch(UpdateAssignmentProductMassive())
            if(response) setLoadingAssignment(false)
        }else{
            notification['warning']({
                message: 'Precaución',
                description: 'Los sentimos, el campo producto asignado y un min es obligatorio.',
            })
        }
    }

    const columns = [
        {
            title: 'Código material',
            dataIndex: 'codigo_material',
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material}>{codigo_material}</div>,
            width: '80px'
        },
        {
            title: 'Material',
            dataIndex: 'material',
            showSorterTooltip: false,
            render: (material) => <div title={material}>{material}</div>,
            width: '120px'
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            showSorterTooltip: false,
            render: (categoria) => <div title={categoria}>{categoria}</div>,
            width: '80px'
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'subcategoria',
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria}>{subcategoria}</div>,
            width: '80px'
        },
    ]

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Title 
                            className="Title-Module" 
                            style={{lineHeight: '20px'}}
                        >
                            Asignacion de Producto para homologación masiva
                        </Title>
                    }
                    className="Card-Item"
                >
                    <Row gutter={20}>
                        <Col span={9}>
                            <Row gutter={10}>
                                <Col span={19}>
                                    <div className="Title-Product-Assignment">Producto asignado: </div>
                                    <Input 
                                        className="Input-Product-Assignment"
                                        disabled={true} 
                                        value={
                                            rex_selected_product.codigo_material || rex_selected_product.material
                                            ? rex_selected_product.codigo_material + " - " + rex_selected_product.material
                                            : null
                                        } 
                                    />
                                </Col>
                                <Col span={5}>
                                    <div className="Title-Product-Assignment">Un Min: </div>
                                    <Input 
                                        className="Input-Product-Assignment"
                                        onChange={(e) => {
                                            if(/^\d*([.,]\d*)?$/.test(e.target.value)){
                                                dispatch(ChangeUnMinAssignmentProductMassive(e.target.value))
                                            }
                                        }}
                                        value={rex_un_min_product_massive}
                                    />
                                </Col>
                            </Row>
                            <div className="Container-Button-Assignment-Product">
                                <Button 
                                    type="primary" 
                                    ghost
                                    style={{ width: '130px' }}
                                    onClick={() => assignmentProductMassive()}
                                >
                                    { 
                                        loadingAssignment
                                        ? <LoadingOutlined />
                                        : <>
                                            <SaveOutlined style={{marginRight:'6px'}}/>
                                            Asignar
                                        </>
                                    }
                                </Button>
                            </div>
                        </Col>
                        <Col span={15}>
                            <Tooltip
                                title="Al momento de escribir en el buscador aparecerán los productos."
                                color="#4096ff"
                            >
                                <Input
                                    style={{marginBottom: '10px'}}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                        if(e.target.value){
                                            dispatch(LoadingAssignmentProductMassive())
                                        }
                                    }}
                                    placeholder="Buscar productos..." 
                                />
                            </Tooltip>
                            {
                                searchText
                                ? rex_loading_assignment_product_massive
                                    ? <div style={{marginTop:'10px'}}>
                                        <Skeleton/>
                                        <Skeleton/>
                                    </div>
                                    : <Table 
                                        pagination={{
                                            position: ["none", "topRight"],
                                            pageSize: 15,
                                        }}
                                        loading={rex_loading_assignment_product_massive}
                                        onRow={selectedRow}
                                        columns={columns}
                                        dataSource={rex_data_assignment_product_massive} 
                                        className="Table-Home Table-Assignment-Product-Massive"
                                        size='small'
                                    />
                                : null
                            }
                        </Col>
                    </Row>
                </Card>
            </Card>
        </div>
    )
}

export default AssignmentProductMassive