import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button } from 'antd'
import {
    MenuOutlined,
    ReloadOutlined
} from '@ant-design/icons'
import { 
    ObtenerMaestraClientesSoftysReducer
} from '../../../Redux/Actions/Administracion/MaestraSoftys/MaestraSoftys'

const MaestraClientesSoftys = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()

    const [ cargandoClientesSoftys, setCargandoClientesSoftys ] = useState(false)

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            // <SearchOutlined
            //     style={{
            //         color: filtered ? '#1890ff' : undefined,
            //     }}
            // />
            <MenuOutlined />
        ),
        onFilter: (value, record) => {
            const text = record[dataIndex] || ''
            return text.toString().toLowerCase().includes(value.toLowerCase())
        }
    })

    const columns = [
        {
            title: 'Código organización ventas',
            dataIndex: 'cod_sales_organizacion',
            ...getColumnSearchProps('cod_sales_organizacion'),
            sorter: (a, b) => a.cod_sales_organizacion.localeCompare(b.cod_sales_organizacion),
            showSorterTooltip: false,
            render: (cod_sales_organizacion) => <div title={cod_sales_organizacion}>{cod_sales_organizacion}</div>,
            width: '120px',
        },
        {
            title: 'Organización ventas',
            dataIndex: 'sales_organizacion',
            ...getColumnSearchProps('sales_organizacion'),
            sorter: (a, b) => a.sales_organizacion.localeCompare(b.sales_organizacion),
            showSorterTooltip: false,
            render: (sales_organizacion) => <div title={sales_organizacion}>{sales_organizacion}</div>,
            width: '120px'
        },
        {
            title: 'Código negocio',
            dataIndex: 'cod_negocio',
            ...getColumnSearchProps('cod_negocio'),
            sorter: (a, b) => a.cod_negocio.localeCompare(b.cod_negocio),
            showSorterTooltip: false,
            render: (cod_negocio) => <div title={cod_negocio}>{cod_negocio}</div>,
            width: '120px'
        },
        {
            title: 'Negocio',
            dataIndex: 'negocio',
            ...getColumnSearchProps('negocio'),
            sorter: (a, b) => a.negocio.localeCompare(b.negocio),
            showSorterTooltip: false,
            render: (negocio) => <div title={negocio}>{negocio}</div>,
            width: '120px'
        },
        {
            title: 'Código ship to',
            dataIndex: 'cod_ship_to',
            ...getColumnSearchProps('cod_ship_to'),
            sorter: (a, b) => a.cod_ship_to.localeCompare(b.cod_ship_to),
            showSorterTooltip: false,
            render: (cod_ship_to) => <div title={cod_ship_to}>{cod_ship_to}</div>,
            width: '130px'
        },
        {
            title: 'Ship to',
            dataIndex: 'ship_to',
            ...getColumnSearchProps('ship_to'),
            sorter: (a, b) => a.ship_to.localeCompare(b.ship_to),
            showSorterTooltip: false,
            render: (ship_to) => <div title={ship_to}>{ship_to}</div>,
            width: '130px'
        },
        {
            title: 'Código sold to',
            dataIndex: 'cod_sold_to',
            ...getColumnSearchProps('cod_sold_to'),
            sorter: (a, b) => a.cod_sold_to.localeCompare(b.cod_sold_to),
            showSorterTooltip: false,
            render: (cod_sold_to) => <div title={cod_sold_to}>{cod_sold_to}</div>,
            width: '130px'
        },
        {
            title: 'Sold to',
            dataIndex: 'sold_to',
            ...getColumnSearchProps('sold_to'),
            sorter: (a, b) => a.sold_to.localeCompare(b.sold_to),
            showSorterTooltip: false,
            render: (sold_to) => <div title={sold_to}>{sold_to}</div>,
            width: '130px'
        },
        {
            title: 'HML código cliente',
            dataIndex: 'hml_cod_cliente',
            ...getColumnSearchProps('hml_cod_cliente'),
            sorter: (a, b) => a.hml_cod_cliente.localeCompare(b.hml_cod_cliente),
            showSorterTooltip: false,
            render: (hml_cod_cliente) => <div title={hml_cod_cliente}>{hml_cod_cliente}</div>,
            width: '130px'
        },
        {
            title: 'HML cliente',
            dataIndex: 'hml_cliente',
            ...getColumnSearchProps('hml_cliente'),
            sorter: (a, b) => a.hml_cliente.localeCompare(b.hml_cliente),
            showSorterTooltip: false,
            render: (hml_cliente) => <div title={hml_cliente}>{hml_cliente}</div>,
            width: '130px'
        },
        {
            title: 'HML código subsidiario',
            dataIndex: 'hml_cod_subsidiario',
            ...getColumnSearchProps('hml_cod_subsidiario'),
            sorter: (a, b) => a.hml_cod_subsidiario.localeCompare(b.hml_cod_subsidiario),
            showSorterTooltip: false,
            render: (hml_cod_subsidiario) => <div title={hml_cod_subsidiario}>{hml_cod_subsidiario}</div>,
            width: '130px'
        },
        {
            title: 'HML subsidiario',
            dataIndex: 'hml_subsidiario',
            ...getColumnSearchProps('hml_subsidiario'),
            sorter: (a, b) => a.hml_subsidiario.localeCompare(b.hml_subsidiario),
            showSorterTooltip: false,
            render: (hml_subsidiario) => <div title={hml_subsidiario}>{hml_subsidiario}</div>,
            width: '130px'
        },
        {
            title: 'HML código provincia',
            dataIndex: 'hml_cod_provincia',
            ...getColumnSearchProps('hml_cod_provincia'),
            sorter: (a, b) => a.hml_cod_provincia.localeCompare(b.hml_cod_provincia),
            showSorterTooltip: false,
            render: (hml_cod_provincia) => <div title={hml_cod_provincia}>{hml_cod_provincia}</div>,
            width: '130px'
        },
        {
            title: 'HML provincia',
            dataIndex: 'hml_provincia',
            ...getColumnSearchProps('hml_provincia'),
            sorter: (a, b) => a.hml_provincia.localeCompare(b.hml_provincia),
            showSorterTooltip: false,
            render: (hml_provincia) => <div title={hml_provincia}>{hml_provincia}</div>,
            width: '130px'
        },
        {
            title: 'HML código distrito',
            dataIndex: 'hml_cod_distrito',
            ...getColumnSearchProps('hml_cod_distrito'),
            sorter: (a, b) => a.hml_cod_distrito.localeCompare(b.hml_cod_distrito),
            showSorterTooltip: false,
            render: (hml_cod_distrito) => <div title={hml_cod_distrito}>{hml_cod_distrito}</div>,
            width: '130px'
        },
        {
            title: 'HML distrito',
            dataIndex: 'hml_distrito',
            ...getColumnSearchProps('hml_distrito'),
            sorter: (a, b) => a.hml_distrito.localeCompare(b.hml_distrito),
            showSorterTooltip: false,
            render: (hml_distrito) => <div title={hml_distrito}>{hml_distrito}</div>,
            width: '130px'
        },
        {
            title: 'HML dirección',
            dataIndex: 'hml_direccion',
            ...getColumnSearchProps('hml_direccion'),
            sorter: (a, b) => a.hml_direccion.localeCompare(b.hml_direccion),
            showSorterTooltip: false,
            render: (hml_direccion) => <div title={hml_direccion}>{hml_direccion}</div>,
            width: '130px'
        },
        {
            title: 'Class 01',
            dataIndex: 'class_one',
            ...getColumnSearchProps('class_one'),
            sorter: (a, b) => a.class_one.localeCompare(b.class_one),
            showSorterTooltip: false,
            render: (class_one) => <div title={class_one}>{class_one}</div>,
            width: '130px'
        },
        {
            title: 'Class 02',
            dataIndex: 'class_two',
            ...getColumnSearchProps('class_two'),
            sorter: (a, b) => a.class_two.localeCompare(b.class_two),
            showSorterTooltip: false,
            render: (class_two) => <div title={class_two}>{class_two}</div>,
            width: '130px'
        },
        {
            title: 'Class 03',
            dataIndex: 'class_three',
            ...getColumnSearchProps('class_three'),
            sorter: (a, b) => a.class_three.localeCompare(b.class_three),
            showSorterTooltip: false,
            render: (class_three) => <div title={class_three}>{class_three}</div>,
            width: '130px'
        },
        {
            title: 'Class 04',
            dataIndex: 'class_four',
            ...getColumnSearchProps('class_four'),
            sorter: (a, b) => a.class_four.localeCompare(b.class_four),
            showSorterTooltip: false,
            render: (class_four) => <div title={class_four}>{class_four}</div>,
            width: '130px'
        },
        {
            title: 'Class 05',
            dataIndex: 'class_five',
            ...getColumnSearchProps('class_five'),
            sorter: (a, b) => a.class_five.localeCompare(b.class_five),
            showSorterTooltip: false,
            render: (class_five) => <div title={class_five}>{class_five}</div>,
            width: '130px'
        },
        {
            title: 'Class 06',
            dataIndex: 'class_six',
            ...getColumnSearchProps('class_six'),
            sorter: (a, b) => a.class_six.localeCompare(b.class_six),
            showSorterTooltip: false,
            render: (class_six) => <div title={class_six}>{class_six}</div>,
            width: '130px'
        },
        {
            title: 'Class 07',
            dataIndex: 'class_seven',
            ...getColumnSearchProps('class_seven'),
            sorter: (a, b) => a.class_seven.localeCompare(b.class_seven),
            showSorterTooltip: false,
            render: (class_seven) => <div title={class_seven}>{class_seven}</div>,
            width: '130px'
        },
        {
            title: 'Class 08',
            dataIndex: 'class_eight',
            ...getColumnSearchProps('class_eight'),
            sorter: (a, b) => a.class_eight.localeCompare(b.class_eight),
            showSorterTooltip: false,
            render: (class_eight) => <div title={class_eight}>{class_eight}</div>,
            width: '130px'
        },
        {
            title: 'Class 09',
            dataIndex: 'class_nine',
            ...getColumnSearchProps('class_nine'),
            sorter: (a, b) => a.class_nine.localeCompare(b.class_nine),
            showSorterTooltip: false,
            render: (class_nine) => <div title={class_nine}>{class_nine}</div>,
            width: '130px'
        },
        {
            title: 'Class 10',
            dataIndex: 'class_ten',
            ...getColumnSearchProps('class_ten'),
            sorter: (a, b) => a.class_ten.localeCompare(b.class_ten),
            showSorterTooltip: false,
            render: (class_ten) => <div title={class_ten}>{class_ten}</div>,
            width: '130px'
        },
        {
            title: 'Codigo departamento',
            dataIndex: 'hml_cod_departamento',
            ...getColumnSearchProps('hml_cod_departamento'),
            sorter: (a, b) => a.hml_cod_departamento.localeCompare(b.hml_cod_departamento),
            showSorterTooltip: false,
            render: (hml_cod_departamento) => <div title={hml_cod_departamento}>{hml_cod_departamento}</div>,
            width: '130px'
        },
        {
            title: 'Departamento',
            dataIndex: 'hml_departamento',
            ...getColumnSearchProps('hml_departamento'),
            sorter: (a, b) => a.hml_departamento.localeCompare(b.hml_departamento),
            showSorterTooltip: false,
            render: (hml_departamento) => <div title={hml_departamento}>{hml_departamento}</div>,
            width: '130px'
        },
        {
            title: 'Codigo pais',
            dataIndex: 'hml_cod_pais',
            ...getColumnSearchProps('hml_cod_pais'),
            sorter: (a, b) => a.hml_cod_pais.localeCompare(b.hml_cod_pais),
            showSorterTooltip: false,
            render: (hml_cod_pais) => <div title={hml_cod_pais}>{hml_cod_pais}</div>,
            width: '130px'
        },
        {
            title: 'Pais',
            dataIndex: 'hml_pais',
            ...getColumnSearchProps('hml_pais'),
            sorter: (a, b) => a.hml_pais.localeCompare(b.hml_pais),
            showSorterTooltip: false,
            render: (hml_pais) => <div title={hml_pais}>{hml_pais}</div>,
            width: '130px'
        },
    ]

    
    const ObtenerClientesSoftys = async () => {
        setCargandoClientesSoftys(true)
        await dispatch(ObtenerMaestraClientesSoftysReducer())
        setCargandoClientesSoftys(false)
    }

    useEffect(() => {
        ObtenerClientesSoftys()
    }, [])

    
    const {
        rex_data_maestra_clientes_softys
    } = useSelector(({maestraSoftys}) => maestraSoftys)

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={<Title level={4} style={{marginBottom: 0}}>Maestra de Clientes Softys</Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerClientesSoftys()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_clientes_softys.length)} registros</div>
                    </div>
                    <Table 
                        pagination={{
                            position: ["topRight", "none"],
                        }}
                        scroll={{
                            x: '1500px',
                            y: '330px',
                        }}
                        loading={cargandoClientesSoftys}
                        columns={columns}
                        dataSource={rex_data_maestra_clientes_softys} 
                        className="Table-Home"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default MaestraClientesSoftys