import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ObtenerMaestraProductosReducer } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import { Typography, Table, Card, Input, Button } from 'antd'
import moment from 'moment'
import {
    MenuOutlined,
    ReloadOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'

const MaestraProductos = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<MenuOutlined />),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })

    
    const {
        rex_data_maestra_productos,
        rex_cargando_data_maestra_productos
    } = useSelector(({maestraProductos}) => maestraProductos)

    const columns = [
        {
            title: 'Código material',
            dataIndex: 'codigo_material',
            ...getColumnSearchProps('codigo_material', 'Código material'),
            sorter: (a, b) => a.codigo_material ? a.codigo_material.localeCompare(b.codigo_material) : false,
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material}>{codigo_material}</div>,
            width: '130px'
        },
        {
            title: 'Material',
            dataIndex: 'material',
            ...getColumnSearchProps('material', 'Material'),
            sorter: (a, b) => a.material ? a.material.localeCompare(b.material) : false,
            showSorterTooltip: false,
            render: (material) => <div title={material}>{material}</div>,
            width: '130px'
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            ...getColumnSearchProps('categoria', 'Categoría'),
            sorter: (a, b) => a.categoria ? a.categoria.localeCompare(b.categoria) : false,
            showSorterTooltip: false,
            render: (categoria) => <div title={categoria}>{categoria}</div>,
            width: '130px'
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'subcategoria',
            ...getColumnSearchProps('subcategoria', 'Sub Categoría'),
            sorter: (a, b) => a.subcategoria ? a.subcategoria.localeCompare(b.subcategoria) : false,
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria}>{subcategoria}</div>,
            width: '130px'
        },
        {
            title: 'Formato',
            dataIndex: 'formato',
            ...getColumnSearchProps('formato', 'Formato'),
            sorter: (a, b) => a.formato ? a.formato.localeCompare(b.formato): false,
            showSorterTooltip: false,
            render: (formato) => <div title={formato}>{formato}</div>,
            width: '130px'
        },
        {
            title: 'Codigo EAN',
            dataIndex: 'codigo_ean',
            ...getColumnSearchProps('codigo_ean', 'Codigo EAN'),
            sorter: (a, b) => a.codigo_ean ? a.codigo_ean.localeCompare(b.codigo_ean) : false,
            showSorterTooltip: false,
            render: (codigo_ean) => <div title={codigo_ean}>{codigo_ean}</div>,
            width: '130px'
        },
        {
            title: 'Attr 1',
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1', 'Attr 1'),
            sorter: (a, b) => a.attr_1 ? a.attr_1.localeCompare(b.attr_1) : false,
            showSorterTooltip: false,
            render: (attr_1) => <div title={attr_1}>{attr_1}</div>,
            width: '130px'
        },
        {
            title: 'Attr 2',
            dataIndex: 'attr_2',
            ...getColumnSearchProps('attr_2', 'Attr 2'),
            sorter: (a, b) => a.attr_2 ? a.attr_2.localeCompare(b.attr_2) : false,
            showSorterTooltip: false,
            render: (attr_2) => <div title={attr_2}>{attr_2}</div>,
            width: '130px'
        },
        {
            title: 'Attr 3',
            dataIndex: 'attr_3',
            ...getColumnSearchProps('attr_3', 'Attr 3'),
            sorter: (a, b) => a.attr_3 ? a.attr_3.localeCompare(b.attr_3) : false,
            showSorterTooltip: false,
            render: (attr_3) => <div title={attr_3}>{attr_3}</div>,
            width: '130px'
        },
        {
            title: 'Attr 4',
            dataIndex: 'attr_4',
            ...getColumnSearchProps('attr_4', 'Attr 4'),
            sorter: (a, b) => a.attr_4 ? a.attr_4.localeCompare(b.attr_4) : false,
            showSorterTooltip: false,
            render: (attr_4) => <div title={attr_4}>{attr_4}</div>,
            width: '130px'
        },
        {
            title: 'Attr 5',
            dataIndex: 'attr_5',
            ...getColumnSearchProps('attr_5', 'Attr 5'),
            sorter: (a, b) => a.attr_5 ? a.attr_5.localeCompare(b.attr_5) : false,
            showSorterTooltip: false,
            render: (attr_5) => <div title={attr_5}>{attr_5}</div>,
            width: '130px'
        },
        {
            title: 'Attr 6',
            dataIndex: 'attr_6',
            ...getColumnSearchProps('attr_6', 'Attr 6'),
            sorter: (a, b) => a.attr_6 ? a.attr_6.localeCompare(b.attr_6) : false,
            showSorterTooltip: false,
            render: (attr_6) => <div title={attr_6}>{attr_6}</div>,
            width: '130px'
        },
        {
            title: 'EQ CJS',
            dataIndex: 'eq_cjs',
            ...getColumnSearchProps('eq_cjs', 'EQ CJS'),
            sorter: (a, b) => a.eq_cjs ? a.eq_cjs - b.eq_cjs : false,
            showSorterTooltip: false,
            render: (eq_cjs) => <div title={eq_cjs}>{eq_cjs}</div>,
            width: '130px'
        },
        {
            title: 'EQ TON',
            dataIndex: 'eq_ton',
            ...getColumnSearchProps('eq_ton', 'EQ TON'),
            sorter: (a, b) => a.eq_ton ? a.eq_ton - b.eq_ton : false,
            showSorterTooltip: false,
            render: (eq_ton) => <div title={eq_ton}>{eq_ton}</div>,
            width: '130px'
        },
        {
            title: 'EQ GSU',
            dataIndex: 'eq_gsu',
            ...getColumnSearchProps('eq_gsu', 'EQ GSU'),
            sorter: (a, b) => a.eq_gsu ? a.eq_gsu - b.eq_gsu : false,
            showSorterTooltip: false,
            render: (eq_gsu) => <div title={eq_gsu}>{eq_gsu}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES NIV',
            dataIndex: 'eq_soles_niv',
            ...getColumnSearchProps('eq_soles_niv', 'EQ SOLES NIV'),
            sorter: (a, b) => a.eq_soles_niv ? a.eq_soles_niv - b.eq_soles_niv : false,
            showSorterTooltip: false,
            render: (eq_soles_niv) => <div title={eq_soles_niv}>{eq_soles_niv}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES NET',
            dataIndex: 'eq_soles_net',
            ...getColumnSearchProps('eq_soles_net', 'EQ SOLES NET'),
            sorter: (a, b) => a.eq_soles_net ? a.eq_soles_net - b.eq_soles_net : false,
            showSorterTooltip: false,
            render: (eq_soles_net) => <div title={eq_soles_net}>{eq_soles_net}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES INVOICE',
            dataIndex: 'eq_soles_invoice',
            ...getColumnSearchProps('eq_soles_invoice', 'EQ SOLES INVOICE'),
            sorter: (a, b) => a.eq_soles_invoice ? a.eq_soles_invoice - b.eq_soles_invoice : false,
            showSorterTooltip: false,
            render: (eq_soles_invoice) => <div title={eq_soles_invoice}>{eq_soles_invoice}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES DSCTO',
            dataIndex: 'eq_soles_dscto',
            ...getColumnSearchProps('eq_soles_dscto', 'EQ SOLES DSCTO'),
            sorter: (a, b) => a.eq_soles_dscto ? a.eq_soles_dscto - b.eq_soles_dscto : false,
            showSorterTooltip: false,
            render: (eq_soles_dscto) => <div title={eq_soles_dscto}>{eq_soles_dscto}</div>,
            width: '130px'
        },
        {
            title: 'Fec. Actualización',
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha actualización'),
            sorter: (a, b) => {
                const creacionA = a.created_at || ''
                const creacionB = b.created_at || ''
                return creacionA.localeCompare(creacionB)
            },
            showSorterTooltip: false,
            render: (marca) => <div title={marca}>{moment(marca).format('DD/MM/YYYY')}</div>,
            width: '120px'
        }
    ]

    const ObtenerProductos = async () => {
        await dispatch(ObtenerMaestraProductosReducer())
    }

    useEffect(() => {
        if(rex_data_maestra_productos.length == 0){
            ObtenerProductos()
        }
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} style={{marginRight:'10px'}}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Title level={4} style={{marginBottom: 0}}>Maestra de Productos</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerProductos()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos.length)} registros</div>
                    </div>
                    
                    <Table 
                        pagination={{
                            position: ["topRight", "none"],
                            pageSize:15
                        }}
                        scroll={{
                            x: '1500px',
                            y: '430px',
                        }}
                        loading={rex_cargando_data_maestra_productos}
                        columns={columns}
                        dataSource={rex_data_maestra_productos} 
                        className="Table-Home"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default MaestraProductos