import {useSelector} from "react-redux";

export default function(permiso, componente)
{
    const {LoginUsuario} = useSelector(({login}) => login);

    let permisos = LoginUsuario.permisos

    if(localStorage.getItem('tpuprivilegio') == "todo"){
        return componente
    }

    let tienePermiso = false

    permisos.map((pem) => {
        if(permiso == pem.pemslug){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return componente
    }else{
        return null
    }
}


export function funPermisosObtenidos(permisos = [], permiso, componente)
{
    if(localStorage.getItem('tpuprivilegio') == "todo"){
        return componente
    }

    let tienePermiso = false

    permisos.map((pem) => {
        const permisoExist = pem.pempermisos ? pem.pempermisos.pemslug : null
        if(permisoExist == permiso){
            tienePermiso = true
        }
    })

    if(tienePermiso){
        return componente
    }else{
        return null
    }
}

export function funPermisosObtenidosBoolean(permisos = [], permiso)
{
    if(localStorage.getItem('tpuprivilegio') == "todo"){
        return true
    }

    const array_perms = Array.isArray(permiso) ? true : false
    let tienePermiso = false

    if(array_perms){
        if(permiso.length > 0){
            tienePermiso = true
        }
    }else{
        permisos.map(pems => {
            const permisoExist = pems.pempermisos ? pems.pempermisos.pemslug : null
            if(permisoExist == permiso){
                tienePermiso = true
            }
        })
    }

    if(tienePermiso){
        return true
    }else{
        return false
    }
}

export function funPermisosObtenidosBooleanChildren(permisos = [], permiso)
{
    if(localStorage.getItem('tpuprivilegio') == "todo"){
        return permiso
    }

    let perms = permiso
    let children = []

    permisos.map(pems => {
        const permisoExist = pems.pempermisos ? pems.pempermisos.pemslug : null
        permiso.children.map(per => {
            if(permisoExist == per.slug){
                children.push({...per})
            }
        })
    })

    perms = {
        ...perms,
        children: children,
    }

    return perms
}