import config from '../../../../config'
import { notification } from 'antd'
import { 
    LOADING_DATA_DESCARGAS_ARCHIVOS,
    // FILTRO_TIPO_ARCHIVOS_CARGA_ARCHIVOS,
    // MOSTRAR_SOLO_ARCHIVOS_CARGADOS_CARGA_ARCHIVOS,
    // MOSTRAR_SOLO_ARCHIVOS_PROCESADOS_CARGA_ARCHIVOS,
    GET_DATA_DESCARGAS_ARCHIVOS,
    GET_DATA_DESCARGAS_ARCHIVOS_COPY,
    GET_DATA_TIPOS_ARCHIVOS_DESCARGAS_ARCHIVOS,
    GET_DATA_TEMPLATE_DESCARGAS_ARCHIVOS,
} from '../../../../Constans/Administracion/Administrador/DescargasArchivos'

const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}

export const GetDataDescargasArchivosReducer = () => async (dispatch) => {
    dispatch({
        type    : LOADING_DATA_DESCARGAS_ARCHIVOS,
        payload : true
    })

    await fetch(config.api_url+'administration/get-download-files',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {           
        if(data.response){

            const data_descargas_archivos = data.data
            dispatch({
                type: GET_DATA_DESCARGAS_ARCHIVOS,
                payload: data_descargas_archivos
            })

            dispatch({
                type: GET_DATA_DESCARGAS_ARCHIVOS_COPY,
                payload: data_descargas_archivos
            })

            const obtener_tipos_archivos = data_descargas_archivos.map(des => des.dartipo)
            const tipos_archivos = [...new Set(obtener_tipos_archivos)]

            dispatch({
                type : GET_DATA_TIPOS_ARCHIVOS_DESCARGAS_ARCHIVOS,
                payload : tipos_archivos
            })

        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : LOADING_DATA_DESCARGAS_ARCHIVOS,
        payload : false
    })
}

export const FiltrarTiposArchivoDescargasArchivosReducer = (cartipo_value) => async ( dispatch, getState ) => {
    const data_descargas_archivos = getState().descargasArchivos.rex_data_descargas_archivos

    if(cartipo_value == ''){
        data_descargas_archivos.map((des, index) => data_descargas_archivos[index]["index"] = index + 1)

        dispatch({
            type    : GET_DATA_DESCARGAS_ARCHIVOS_COPY,
            payload : data_descargas_archivos
        })
    }else{
        const data_filter = data_descargas_archivos.filter(dat => (dat.dartipo.includes(cartipo_value)))
        data_filter.map((dat, index) => data_filter[index]['index'] = index + 1)

        dispatch({
            type    : GET_DATA_DESCARGAS_ARCHIVOS_COPY,
            payload : data_filter
        })
    }
}

export const DeleteFileDescargasArchivosReducer = (id) => async ( dispatch ) => {
    await fetch(config.api_url+'administration/delete-download-files',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_delete_id  : id,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch(GetDataDescargasArchivosReducer())
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('error', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const GetDataTemplateDownloadFiles = () => async (dispatch) => {
    await fetch(config.api_url+'administration/get-template-download-files',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_TEMPLATE_DESCARGAS_ARCHIVOS,
                payload: data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}