import {
    OBTENER_DATA_CARGA_ARCHIVOS,
    CARGANDO_DATA_CARGA_ARCHIVOS,
    OBTENER_DATA_CARGA_ARCHIVOS_COPIA,
    OBTENER_DATA_TIPOS_ARCHIVOS_CARGA_ARCHIVOS,
    MOSTRAR_SOLO_ARCHIVOS_PROCESADOS_CARGA_ARCHIVOS,
    MOSTRAR_SOLO_ARCHIVOS_CARGADOS_CARGA_ARCHIVOS,
    FILTRO_TIPO_ARCHIVOS_CARGA_ARCHIVOS
} from "../../../../Constans/Administracion/Administrador/CargaArchivos";

const INIT_STATE = {
    rex_data_carga_archivos                             : [],
    rex_data_carga_archivos_copia                       : [],
    rex_data_tipos_archivos_carga_archivos              : [],
    rex_cargando_data_carga_archivos                    : true,
    rex_mostrar_solo_archivos_procesados_carga_archivos : false,
    rex_mostrar_solo_archivos_cargados_carga_archivos   : true,
    rex_filtro_tipo_archivos_carga_archivos             : false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FILTRO_TIPO_ARCHIVOS_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_filtro_tipo_archivos_carga_archivos : action.payload
            }
        }
        case MOSTRAR_SOLO_ARCHIVOS_CARGADOS_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_mostrar_solo_archivos_cargados_carga_archivos : action.payload
            }
        }
        case MOSTRAR_SOLO_ARCHIVOS_PROCESADOS_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_mostrar_solo_archivos_procesados_carga_archivos : action.payload
            }
        }
        case OBTENER_DATA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_data_carga_archivos : action.payload
            }
        }
        case OBTENER_DATA_CARGA_ARCHIVOS_COPIA: {
            return {
                ...state,
                rex_data_carga_archivos_copia : action.payload
            }
        }
        case OBTENER_DATA_TIPOS_ARCHIVOS_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_data_tipos_archivos_carga_archivos : action.payload
            }
        }
        case CARGANDO_DATA_CARGA_ARCHIVOS: {
            return {
                ...state,
                rex_cargando_data_carga_archivos : action.payload
            }
        }
        default:
            return state;
    }
}
