import {
    OBTENER_DATA_TIPOS_USUARIOS,
    OBTENER_DATA_TIPOS_USUARIOS_PERMISOS,
    OBTENER_DATA_TIPO_USUARIO,
    OBTENER_DATA_TIPO_USUARIO_NUEVO,
    CARGANDO_DATA_TIPOS_USUARIOS
} from "../../../../Constans/Administracion/Administrador/TiposUsuarios";

const INIT_STATE = {
    rex_data_tipos_usuarios             : [],
    rex_cargando_data_tipos_usuarios    : true,
    rex_data_tipo_usuario               : [],
    rex_data_tipo_usuario_nuevo         : [],
    rex_data_tipos_usuarios_permisos    : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CARGANDO_DATA_TIPOS_USUARIOS: {
            return {
                ...state,
                rex_cargando_data_tipos_usuarios : action.payload
            }
        }

        case OBTENER_DATA_TIPOS_USUARIOS: {
            return {
                ...state,
                rex_data_tipos_usuarios : action.payload
            }
        }
        case OBTENER_DATA_TIPO_USUARIO_NUEVO: {
            return {
                ...state,
                rex_data_tipo_usuario_nuevo : action.payload
            }
        }
        case OBTENER_DATA_TIPO_USUARIO: {
            return {
                ...state,
                rex_data_tipo_usuario : action.payload
            }
        }
        case OBTENER_DATA_TIPOS_USUARIOS_PERMISOS: {
            return {
                ...state,
                rex_data_tipos_usuarios_permisos : action.payload
            }
        }
        default:
            return state;
    }
}
