import config from '../../../config'
import { notification } from 'antd'
import {
    DATA_USER_LOGIN,
    DATA_USER_PERMISSIONS,
    SESSION_INACTIVITY,
    VALUE_EMAIL_AZURE,
} from '../../../Constans/Login/Login'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const LoginReducer = (usu_correo, usu_pais, usu_contrasenia) => async ( dispatch ) => {
    let val = false
    await fetch(config.api_public+'log-in',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usuusuario: usu_correo,
                req_paiid: usu_pais,
                req_usucontrasenia: usu_contrasenia
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.respuesta){
            localStorage.setItem('usutoken', data.auth_token)
            localStorage.setItem('token_val', data.user.token_val)
            localStorage.setItem('tpuprivilegio', data.user.tputiposusuarios.tpuprivilegio)
            dispatch({
                type: DATA_USER_LOGIN,
                payload: data.user
            })
            dispatch({
                type: DATA_USER_PERMISSIONS,
                payload: data.user.tputiposusuarios.tuptiposusuariospermisos
            })
            notificacionServidor('success', data.message)
            val = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return val
}

export const LogOutReducer = () => async (dispatch, getState) => {
    // await fetch(config.api+'/log-out-azure',
    // {
    //     mode:'cors',
    //     method: 'GET',
    //     headers: {
    //         'Accept' 	   : 'application/json',
    //         'Content-type' : 'application/json',
    //         'usutoken'	   : localStorage.getItem('usutoken'),
    //     }
    // })
    // .then(async res => {
    //     localStorage.clear()
    //     dispatch({
    //         type: DATA_USER_LOGIN,
    //         payload: []
    //     })
    // }).catch((error)=> {
    //     console.log(error)
    // })

    localStorage.clear()
    dispatch({
        type: DATA_USER_LOGIN,
        payload: []
    })

    // window.location.href = `${config.api}/log-out-azure`

    return true
}

export const FindUserAzureReducer = (user_correo) => async (dispatch) => {
    let response = false
    await fetch(config.api_public+'validate-email-azure',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usuusuario: user_correo,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        response = data.response
        if(!data.response){
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const ChangeEmailAzureReducer = (value) => async (dispatch, getState) => {
    dispatch({
        type: VALUE_EMAIL_AZURE,
        payload: value,
    })
}

export const InactivityLogOutReducer = () => async (dispatch, getState) => {
    localStorage.clear()
    dispatch({
        type: DATA_USER_LOGIN,
        payload: []
    })
    dispatch({
        type: SESSION_INACTIVITY,
        payload: true
    })
}

export const ActivityLogIn = () => async (dispatch, getState) => {
    dispatch({
        type: SESSION_INACTIVITY,
        payload: false
    })
}