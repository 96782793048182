import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Button, Popconfirm, Input, Pagination, Skeleton } from 'antd'
import {
    EliminarUsuarioReducer,
    ObtenerDataUsuariosReducer
} from '../../../Redux/Actions/Administracion/Administrador/Usuarios'
import {
    DeleteOutlined,
    EditOutlined,
    ReloadOutlined,
    PlusOutlined,
    ArrowLeftOutlined,
    FilterOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import '../../../Styles/Components/Administracion/Administrador/Usuarios.css'


const Usuarios = (props) => {

    const collapsed = props.collapsed
    const { Title } = Typography
    const [ pageTable, setPageTable ] = useState(1)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const {
        rex_data_usuarios,
        rex_cargando_data_usuarios
    } = useSelector(({usuarios}) => usuarios)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (
                        <FilterOutlined style={{color:'white'}}/>
                    ),
        onFilter: (value, record) => record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
    })

    const ObtenerUsuarios = async () => {
        await dispatch(ObtenerDataUsuariosReducer())
    }
    
    useEffect(() => {
        if(rex_data_usuarios.length == 0){
            dispatch(ObtenerDataUsuariosReducer())
        }
    },[])

    const columns = [
        {
            title: 'N°',
            dataIndex: 'index',
            sorter: (a, b) => a.index ? a.index - b.index : false,
            showSorterTooltip: false,
            render: (_, record) => <div>{record.index}</div>,
            width: '20px',
            align:'center'
        },
        {
            title: 'Estado',
            dataIndex: 'estid',
            sorter: (a, b) => a.estid ? a.estid - b.estid : false,
            showSorterTooltip: false,
            render: (_, record) => 
                            <div style={{display:'flex', justifyContent:'center'}} title={record.estid ? 'Activado' : 'Desactivado'}>
                                <div className={`Box-Status-User ${record.estid == 1 ? 'Color-Green':'Color-Red'}`}>
                                </div>
                            </div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Tipo de Usuario',
            dataIndex: 'tpunombre',
            ...getColumnSearchProps('tpunombre', 'tipo de usuario'),
            sorter: (a, b) => a.tpunombre? a.tpunombre.localeCompare(b.tpunombre) : false,
            showSorterTooltip: false,
            render: (_, record) => <div title={record.tpunombre}>{record.tpunombre}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Usuario',
            dataIndex: 'usuusuario',
            ...getColumnSearchProps('usuusuario', 'usuario'),
            sorter: (a, b) => a.usuusuario? a.usuusuario.localeCompare(b.usuusuario) : false,
            showSorterTooltip: false,
            render: (usuusuario) => <div title={usuusuario}>{usuusuario}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Correo',
            dataIndex: 'usucorreo',
            ...getColumnSearchProps('usucorreo', 'correo'),
            sorter: (a, b) => a.usucorreo? a.usucorreo.localeCompare(b.usucorreo) : false,
            showSorterTooltip: false,
            render: (usucorreo) => <div title={usucorreo}>{usucorreo}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Nombres y apellidos',
            dataIndex: 'pernombrecompleto',
            ...getColumnSearchProps('pernombrecompleto', 'nombre completo'),
            sorter: (a, b) => a.pernombrecompleto? a.pernombrecompleto.localeCompare(b.pernombrecompleto) : false,
            showSorterTooltip: false,
            render: (_, record) => <div title={record.pernombrecompleto}>{record.pernombrecompleto}</div>,
            width: '100px',
            align:'center'

        },
        {
            title: 'Acciones',
            dataIndex: '',
            render: (_, record) => <div>
                <EditOutlined 
                    title='Editar'
                    onClick={() => {
                        navigate(`/admin/editar-usuario/${record.usuid}`)
                    }}
                    style={{fontSize:'15px', color:'blue', cursor:'pointer', margin:'0 2px'}}
                />
                <Popconfirm
                    title='Eliminar usuario'
                    description='Está seguro de eliminar al usuario?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => dispatch(EliminarUsuarioReducer(record.usuid))}
                >
                    <DeleteOutlined 
                        title='Eliminar'
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>
            </div>,
            width: '50px',
            align:'center'
        }
    ]

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Usuarios</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display:'flex', justifyContent: 'space-between'}}>
                        <div style={{display:'flex'}}>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> {
                                    ObtenerUsuarios()
                                }}    
                            >
                                <ReloadOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Recargar
                            </Button>
                            <Button
                                className='Button-Extra-Option-Type-User' size='small'
                                onClick={()=> {
                                    navigate(`/admin/crear-usuario`)
                                }}    
                            >
                                <PlusOutlined
                                    className='Icon-Button-Extra-Option-Type-User'
                                />Nuevo
                            </Button>
                        </div>
                        { rex_cargando_data_usuarios && rex_data_usuarios.length == 0
                        ? <Skeleton.Input active={true} size='default'/>
                        : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                            <Pagination
                                current={pageTable}
                                total={rex_data_usuarios.length}
                                pageSize={15}
                                onChange={(page, pageSize) => setPageTable(page)}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_usuarios.length)} usuarios</div>
                        </div> 
                        }
                         
                    </div>
                    { rex_cargando_data_usuarios && rex_data_usuarios.length == 0
                    ? <div style={{marginTop:'10px'}}>
                            <Skeleton/>
                            <Skeleton/>
                    </div>
                    : <Table
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 15,
                            current: pageTable,
                        }}
                        columns={columns}
                        dataSource={rex_data_usuarios}
                        className="Table-User Table-Small"
                        size='small'
                    />
                    }
                    
                </Card>
            </Card>
        </div>
    )
}

export default Usuarios