import config from '../../../../config'
import {
    GET_DATA_ADMIN_PLATFORM,
    LOADING_DATA_ADMIN_PLATFORM,
} from '../../../../Constans/Administracion/AdminPlatform/AdminPlatform'
import {
    ADMIN_PLATFORM,
} from '../../../../Constans/Login/Login'
import { notification } from 'antd'

const STATUS_ERR = 400
const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataAdminPlatformReducer = () => async (dispatch, getState) => {
    dispatch({
        type: LOADING_DATA_ADMIN_PLATFORM,
        payload: true
    })

    await fetch(config.api_url+'administration/get-admin-platform',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            let data_admin_platform = {
                main_text: '',
                secondary_text: '',
            }
            if(data.data){
                data_admin_platform = {
                    main_text: data.data.apmtituloprincipal,
                    secondary_text: data.data.apmtitulosecundario
                }
            }

            dispatch({
                type: GET_DATA_ADMIN_PLATFORM,
                payload: data_admin_platform
            })

            dispatch({
                type: ADMIN_PLATFORM,
                payload: data_admin_platform
            })
        }else{
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: LOADING_DATA_ADMIN_PLATFORM,
        payload: false
    })
}

export const CreateUpdateAdminPlatformReducer = () => async (dispatch, getState) => {
    const { 
        rex_data_admin_platform,
    } = getState().adminPlatform

    await fetch(config.api_url+'administration/create-update-admin-platform',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_main_text: rex_data_admin_platform.main_text,
                req_secondary_text: rex_data_admin_platform.secondary_text,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor('success', data.message)
        }else{
            if(data.status == STATUS_ERR){
                notificacionServidor('warning', data.message)
            }else{
                notificacionServidor('error', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })

    return true
}

export const ChangeMainTextReducer = (value, type) => async (dispatch, getState) => {
    const data_admin_platform = getState().adminPlatform.rex_data_admin_platform
    if(type === "main_text"){
        data_admin_platform[type] = value ? value : ''
    }else if(type === "secondary_text"){
        data_admin_platform[type] = value ? value : ''
    }

    dispatch({
        type: GET_DATA_ADMIN_PLATFORM,
        payload: data_admin_platform
    })
}