import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from "react-router-dom"
import {
    ChangeModalUploadDataRestriction,
    ChangeUpdateUploadDataRestriction,
    GetUploadDataRestriction,
    UpdateUploadDataRestriction
} from '../../Redux/Actions/Administracion/Administrador/UploadDataRestriction'
import { 
    funPermisosObtenidosBoolean, 
    funPermisosObtenidosBooleanChildren 
} from '../../Functions/funPermiso'
import { Menu, Modal, Form, Input } from 'antd'
import {
    AppstoreOutlined,
    PieChartOutlined,
    HomeOutlined,
    UploadOutlined,
    OrderedListOutlined,
    DownloadOutlined,
    LockOutlined,
    SaveOutlined
} from '@ant-design/icons'
import '../../Styles/Components/Sidebar/Sidebar.css'

const Sidebar = (props) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()

    const {
        rex_data_user_permissions
      } = useSelector(({login}) => login)

    const {
        rex_data_upload_data_restriction,
        rex_data_update_upload_data_restriction,
        rex_open_modal_upload_data_restriction,
    } = useSelector(({uploadDataRestriction}) => uploadDataRestriction)

    const items = [
        {
            type: 'divider',
        },
        {
            key: '/status', label: 'Status', icon: <HomeOutlined />, slug : 'sidebar.status'
        },
        {
            key: '/upload-file', label: 'Upload Data', icon: <UploadOutlined />, slug : 'sidebar.uploadfile'
        },
        {
            key: '/homologados', label: 'SKU (Homologados)', icon: <PieChartOutlined />, slug : 'sidebar.skuhomologados'
        },
        {
            key: '/no-homologados', label: 'SKU (No Homologados)', icon: <PieChartOutlined />, slug : 'sidebar.skunohomologados'
        },
        {
            key: '/download-data', label: 'Download Data', icon: <DownloadOutlined />, slug : 'sidebar.downloadfile'
        },
        {
            key: '/maestra-productos-grow', label: 'Productos', icon: <OrderedListOutlined />, slug : 'sidebar.maestraproductos'
        },
        {
            key: '/maestra-clientes-grow', label: 'Clientes', icon: <OrderedListOutlined />, slug : 'sidebar.maestraclientes'
        },
        {
            key: '/maestra-precios', label: 'Precios', icon: <OrderedListOutlined />, slug : 'sidebar.maestraprecios'
        },
        {
            key: '', 
            label: 'Administrador', 
            icon: null, 
            children: [
                {
                    key: '/admin/plataforma', label: <span title='Admin Plataforma'>Admin Plataforma</span>, icon: <OrderedListOutlined />, slug : 'sidebar.admin.plataforma'
                },
                {
                    key: '/admin/usuarios', label: 'Usuarios', icon: <OrderedListOutlined />, slug : 'sidebar.usuarios'
                },
                {
                    key: '/admin/tipos-usuarios', label: <span title='Tipos de usuarios'>Tipos de usuarios</span>, icon: <OrderedListOutlined />, slug : 'sidebar.tiposusuarios'
                },
                {
                    key: '/admin/carga-archivos', label: 'Carga Archivos', icon: <OrderedListOutlined />, slug : 'sidebar.cargaarchivos'
                },
                {
                    key: '/admin/descargas-archivos', label: <span title='Descargas Archivos'>Descargas Archivos</span>, icon: <OrderedListOutlined />, slug : 'sidebar.descargasArchivos'
                },
                {
                    key: '/audits', label: 'Auditorias', icon: <OrderedListOutlined />, slug : 'sidebar.auditorias'
                },
                {
                    key: '/unidades-de-medidas', label: <span title="Unidades de Medidas">Unidades de Medidas</span>, icon: <OrderedListOutlined />, slug : 'sidebar.unitsmeasures'
                },
                {
                    key: '/asignacion-de-productos-masivo', label: <span title='Asignación de Producto Masivo'>Asignacion de Producto Masiva</span>, icon: <OrderedListOutlined />, slug : 'sidebar.assignmentproductmassive'
                },
            ],
        },
    ]

    const {
        collapsed,
        setCollapsed
    } = props

    const changeModalUploadDataRestriction = async (value) => {
        await dispatch(ChangeModalUploadDataRestriction(value))
    }

    const getUploadDataRestriction = () => {
        dispatch(GetUploadDataRestriction())
    }

    const updateUploadDataRestriction = async () => {
        const response = await dispatch(UpdateUploadDataRestriction())
        if(response) {
            changeModalUploadDataRestriction(false)
            if(pathname == "/upload-file") {
                getUploadDataRestriction()
            }
        }
    }

    const changeDataUpdateUploadDataRestriction = (value, type, name) => {
        dispatch(ChangeUpdateUploadDataRestriction(value, type, name))
    }

    const selectedActiveMenu = () => {
        let find_active_menu = null
        let found = false
        items.map(it => {
            if(found){
                return
            }

            if(it.key == pathname){
                find_active_menu = it
                found = true
            }else if(it.children){
                it.children.map(chil => {
                    if(chil.key == pathname){
                        find_active_menu = chil
                        found = true
                    }
                })
            }
        })

        if(find_active_menu){
            return [`${find_active_menu.key}`]
        }else{
            const sub_items = [
                { route: '/admin/crear-usuario', key: '/admin/usuarios' },
                { route: '/admin/editar-usuario', key: '/admin/usuarios' },
                { route: '/admin/crear-tipo-usuario', key: '/admin/tipos-usuarios' },
                { route: '/admin/editar-tipo-usuario', key: '/admin/tipos-usuarios' },
                { route: '/crear-unidades-de-medidas', key: '/unidades-de-medidas' },
                { route: '/actualizar-unidades-de-medidas', key: '/unidades-de-medidas' },
            ]

            let route_pathname = pathname
            const separate_route = pathname.split("/")
            if(separate_route.length == 4){
                route_pathname = "/" + separate_route[1] + "/" + separate_route[2]
            }

            const find_sub_item = sub_items.find(sub => sub.route == route_pathname)

            if(find_sub_item){
                return [`${find_sub_item.key}`]
            }
        }

        return null
    }

    return (
        <>
        <div>
            <div
                className='Sidebar-Menu'
                style={{ width: '160px' }}
            >
                <Menu
                    defaultSelectedKeys={() => selectedActiveMenu()}
                    // defaultOpenKeys={['sub1']}
                    mode="inline"
                    inlineCollapsed={collapsed}
                    items={
                        items.map(item => {
                            if(item.children){
                                return funPermisosObtenidosBooleanChildren(rex_data_user_permissions, item)
                            }
                            return { ...item }
                        }).filter((item) => funPermisosObtenidosBoolean(rex_data_user_permissions, item.children ? item.children : item.slug))
                    }
                    style={{height: '100vh', border: 0}}
                    onClick={async (e) => {
                        if(e.key == "/upload-file-restriction"){
                            await changeModalUploadDataRestriction(true)
                            await getUploadDataRestriction()
                        }else{
                            navigate(e.key)
                        }
                    }}
                >
                    <Link to="/homologados" key="hml">
                        <Menu.Item key="homologados" >
                            <AppstoreOutlined />
                            <span className="nav-text">Homologados</span>
                        </Menu.Item>
                    </Link>
                    <Link to="/no-homologados" key="no-hml">
                        <Menu.Item key="no-homologados" >
                            <AppstoreOutlined />
                            <span className="nav-text">No Homologados</span>
                        </Menu.Item>
                    </Link>
                </Menu>
            </div>
        </div>
        <Modal 
            className="uploadrestriccion"
            title="Upload Data Restriction" 
            open={rex_open_modal_upload_data_restriction} 
            closable={false}
            onOk={() => updateUploadDataRestriction()} 
            okButtonProps={{
                ghost: true,
                type: "primary",
                icon: <SaveOutlined />
            }}
            onCancel={() => changeModalUploadDataRestriction(false)}
            cancelButtonProps={{
                danger: true,
            }}
        >
            <div className="uploadrestriccion__text">Sección en donde se restringe los días de subida de archivos(upload data).</div>
            <Form
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                labelAlign="left"
            >
                <Form.Item 
                    className="uploadrestriccion__item" 
                    label="Sell Out (Cuota) día"
                    extra={`La subida de archivos estara habilitada desde el día 1 calendario hasta el dia ${rex_data_update_upload_data_restriction.sellout 
                        ? rex_data_update_upload_data_restriction.sellout.tcadiarestriccion 
                        : rex_data_upload_data_restriction.sellout 
                            ? rex_data_upload_data_restriction.sellout.tcadiarestriccion
                            : ""}
                    `}
                >
                    <Input 
                        onChange={(e) => changeDataUpdateUploadDataRestriction(e.target.value, "sellout", "Sell Out")}
                        value={
                            rex_data_update_upload_data_restriction.sellout 
                                ? rex_data_update_upload_data_restriction.sellout.tcadiarestriccion 
                                : rex_data_upload_data_restriction.sellout 
                                    ? rex_data_upload_data_restriction.sellout.tcadiarestriccion
                                    : null
                        } 
                    />
                </Form.Item>
            </Form>
        </Modal>
        </>
    )
}

export default Sidebar