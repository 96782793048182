import config from '../../../../config'
import { 
    OBTENER_DATA_MAESTRA_PRODUCTOS,
    OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
    FILTRO_DATA_MAESTRA_PRODUCTOS_CODIGO,
    FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
    FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA,
    CARGANDO_DATA_MAESTRA_PRODUCTOS
} from '../../../../Constans/Administracion/MaestraProductos/MaestraProductos'

export const ObtenerMaestraProductosReducer = () => async ( dispatch ) => {

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS,
        payload : true
    })

    await fetch(config.api_url+'administration/get-products',
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {

        dispatch({
            type: OBTENER_DATA_MAESTRA_PRODUCTOS,
            payload: data.data
        })
        dispatch({
            type: OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS,
        payload : false
    })
}

export const FiltrarMaestraProductosReducer = (codigo, nombre, categoria) => async (dispatch, getState) =>{
    
    let lista_ma_pro_orig   = getState().maestraProductos.rex_data_maestra_productos
    let mae_pro_filtrado

    //Cuando se hace clear en el select
    if(codigo == undefined){
        codigo = ''
    }
    if(nombre == undefined){
        nombre = ''
    }
    if(categoria == undefined){
        categoria = ''
    }
    
    mae_pro_filtrado = lista_ma_pro_orig.filter((pro) => 
        (
            (pro.codigo_material ? pro.codigo_material.toLowerCase().includes(codigo?.toLocaleLowerCase()) : codigo != '' ? false : true)
            && ( pro.material ? pro.material.toLowerCase().includes(nombre?.toLocaleLowerCase()) : nombre != '' ? false : true)
            && ( pro.categoria ? pro.categoria.toLowerCase().includes(categoria?.toLocaleLowerCase()) : categoria != '' ? false : true)
        )
    )

    if(nombre == '' && codigo == '' && categoria == ''){
        dispatch({
            type    : OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
            payload : lista_ma_pro_orig
        })
    }else{
        dispatch({
            type    : OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
            payload : mae_pro_filtrado
        })
    }
}

export const AsignarFiltrosMaestraProductosReducer = ( ) => async ( dispatch, getState) => {

    let data   = getState().maestraProductos.rex_data_maestra_productos

    let filtro_codigo = []
    let filtro_nombre = []
    let filtro_categoria = []
    
    data.forEach( dat => {
        if(dat.codigo_material){
            let existe_codigo = filtro_codigo.findIndex( (filtro ) => filtro.value ==  dat.codigo_material.toUpperCase())
            if(existe_codigo == -1){
                filtro_codigo.push({
                    label : dat.codigo_material.toUpperCase(),
                    value : dat.codigo_material.toUpperCase()
                })
            }
        }
        if(dat.material){
            let existe_nombre = filtro_nombre.findIndex( (filtro ) => filtro.value ==  dat.material.toUpperCase())
            if(existe_nombre == -1){
                filtro_nombre.push({
                    label : dat.material.toUpperCase(),
                    value : dat.material.toUpperCase()
                })
            }
        }
        if(dat.categoria){
            let existe_categoria = filtro_categoria.findIndex( (filtro ) => filtro.value ==  dat.categoria.toUpperCase())
            if(existe_categoria == -1){
                filtro_categoria.push({
                    label : dat.categoria.toUpperCase(),
                    value : dat.categoria.toUpperCase()
                })
            }
        }
    })

    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_CODIGO,
        payload : filtro_codigo
    })
    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
        payload : filtro_nombre
    })
    dispatch({
        type : FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA,
        payload : filtro_categoria
    })
}