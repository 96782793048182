import {
    GET_PENDING_STATUS,
    LOADING_PENDING_STATUS
} from "../../../Constans/Status/Status"

const INIT_STATE = {
    data_estados_pendientes_home : [],
    data_estados_pendientes_distribuidoras_home : [],
    loading_pending_status : true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case LOADING_PENDING_STATUS: {
            return {
                ...state,
                loading_pending_status : action.payload,
            }
        }
        case GET_PENDING_STATUS: {
            return {
                ...state,
                data_estados_pendientes_home : action.payload.status,
                data_estados_pendientes_distribuidoras_home : action.payload.statusdistribuidoras
            }
        }
        default:
            return state;
    }
}
