import {
    OBTENER_DATA_USUARIOS,
    OBTENER_DATA_EDITAR_USUARIO,
    DATA_NUEVO_USUARIO,
    CARGANDO_DATA_USUARIOS
} from "../../../../Constans/Administracion/Administrador/Usuarios";

const INIT_STATE = {
    rex_data_usuarios           : [],
    rex_data_editar_usuario     : [],
    rex_cargando_data_usuarios  : true,
    rex_data_nuevo_usuario      : {
        usucorreo       : null,
        usuusuario      : null,
        estid           : 1,
        usu_azure       : false,
        tpuid           : null,
        usucontrasenia  : true,
        usutoken        : null,
        perpersonas : {
            pernombre           : null,
            perapellidomaterno  : null,
            perapellidopaterno  : null,
        }
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CARGANDO_DATA_USUARIOS: {
            return {
                ...state,
                rex_cargando_data_usuarios : action.payload
            }
        }
        case OBTENER_DATA_USUARIOS: {
            return {
                ...state,
                rex_data_usuarios : action.payload
            }
        }
        case DATA_NUEVO_USUARIO: {
            return {
                ...state,
                rex_data_nuevo_usuario : action.payload
            }
        }
        case OBTENER_DATA_EDITAR_USUARIO: {
            return {
                ...state,
                rex_data_editar_usuario : action.payload
            }
        }
        default:
            return state;
    }
}
