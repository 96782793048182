export const OBTENER_DATA_NO_HOMOLOGADOS = 'ASIG_OBTENER_DATA_NO_HOMOLOGADOS'
export const OBTENER_DATA_NO_HOMOLOGADOS_CLONE = 'OBTENER_DATA_NO_HOMOLOGADOS_CLONE'
export const GET_DATA_HML = 'GET_DATA_HML'
export const OBTENER_DATA_NO_HOMOLOGADOS_CALENDARY = 'OBTENER_DATA_NO_HOMOLOGADOS_CALENDARY'
export const CARGANDO_DATA_NO_HOMOLOGADOS = 'CARGANDO_DATA_NO_HOMOLOGADOS'
export const DATA_TOTAL_NO_HOMOLOGADOS = 'DATA_TOTAL_NO_HOMOLOGADOS'
export const GROUP_DATA_YEARS = 'GROUP_DATA_YEARS'
export const GROUP_DATA_MONTHS = 'GROUP_DATA_MONTHS'
export const GROUP_DATA_DAYS = 'GROUP_DATA_DAYS'
export const TYPE_CALENDARY = 'TYPE_CALENDARY'
export const CHECK_SELECT = 'CHECK_SELECT'
export const SEARCH_TEXT = 'SEARCH_TEXT'