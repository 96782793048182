import config from '../../../../config'
import {
    OBTENER_DATA_LISTA_PRECIOS 
} from '../../../../Constans/Administracion/ListaPrecios/ListaPrecios'

export const ObtenerListaPreciosReducer = () => async ( dispatch ) => {

    await fetch(config.api_url+'administration/get-list-price',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_LISTA_PRECIOS,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })
}