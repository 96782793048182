import { 
    OBTENER_DATA_MAESTRA_CLIENTES_SOFTYS
} from "../../../../Constans/Administracion/MaestraSoftys/MaestraSoftys";


const INIT_STATE = {
    rex_data_maestra_clientes_softys  : [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case OBTENER_DATA_MAESTRA_CLIENTES_SOFTYS: {
            return {
                ...state,
                rex_data_maestra_clientes_softys : action.payload
            }
        }
        default:
            return state;
    }
}
