import {
    OBTENER_DATA_PERMISO,
    OBTENER_DATA_TIPOS_PERMISOS,
    OBTENER_DATA_PERMISOS
} from "../../../../Constans/Administracion/Administrador/Permisos";

const INIT_STATE = {
    rex_data_permiso            : [],
    rex_data_permisos           : [],
    rex_data_tipos_permisos     : [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case OBTENER_DATA_PERMISO: {
            return {
                ...state,
                rex_data_permiso : action.payload
            }
        }
        case OBTENER_DATA_TIPOS_PERMISOS: {
            return {
                ...state,
                rex_data_tipos_permisos : action.payload
            }
        }
        case OBTENER_DATA_PERMISOS: {
            return {
                ...state,
                rex_data_permisos : action.payload
            }
        }
        default:
            return state;
    }
}
