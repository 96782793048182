import config from '../../../../config'
import { notification } from 'antd'
import {
    OBTENER_DATA_MAESTRA_CLIENTES_GROW,
    OBTENER_DATA_MAESTRA_PRODUCTOS_GROW,
    CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
    CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
    FECHA_ACTUALIZACION_MAESTRA_CLIENTES_GROW,
    FECHA_ACTUALIZACION_MAESTRA_PRODUCTOS_GROW
} from '../../../../Constans/Administracion/MaestraGrow/MaestraGrow'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerMaestraProductosGrowReducer = () => async ( dispatch ) => {

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
        payload : true,
    })

    await fetch(config.api_url+'administration/get-master-products-grow',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_MAESTRA_PRODUCTOS_GROW,
            payload: data.data
        })
        dispatch({
            type : FECHA_ACTUALIZACION_MAESTRA_PRODUCTOS_GROW,
            payload : data.date_updated
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type : CARGANDO_DATA_MAESTRA_PRODUCTOS_GROW,
        payload : false,
    })
}

export const ObtenerMaestraClientesGrowReducer = () => async ( dispatch ) => {

    dispatch({
        type    : CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
        payload : true
    })

    await fetch(config.api_url+'administration/get-master-clients-grow',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_MAESTRA_CLIENTES_GROW,
            payload: data.data
        })
        dispatch({
            type : FECHA_ACTUALIZACION_MAESTRA_CLIENTES_GROW,
            payload : data.date_updated
        })

    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : CARGANDO_DATA_MAESTRA_CLIENTES_GROW,
        payload : false
    })
}

export const DeleteMaestraProductosGrowReducer = (id, codigo_material) => async ( dispatch ) => {
    await fetch(config.api_url+'administration/delete-master-products-grow',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id: id,
                req_codigo_material: codigo_material,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor("success", data.message)
            dispatch(ObtenerMaestraProductosGrowReducer())
        }else{
            if(data.status === 400){
                notificacionServidor("warning", data.message)
            }else{
                notificacionServidor("error", data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const DeleteMaestraClientesGrowReducer = (id, cod_ship_to) => async (dispatch) => {
    await fetch(config.api_url+'administration/delete-master-clients-grow',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id: id,
                req_cod_ship_to: cod_ship_to,
            })
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            notificacionServidor("success", data.message)
            dispatch(ObtenerMaestraClientesGrowReducer())
        }else{
            if(data.status === 400){
                notificacionServidor("warning", data.message)
            }else{
                notificacionServidor("error", data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    })
}