import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button } from 'antd'
import {
    MenuOutlined,
    ArrowLeftOutlined,
    ReloadOutlined
} from '@ant-design/icons'
import { 
    ObtenerDataDistribuidorasReducer
} from '../../../Redux/Actions/Administracion/Distribuidoras/Distribuidoras'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const Distribuidoras = (props) => {

    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [ currentDataSize, setCurrentDataSize ] = useState(null)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <MenuOutlined />
        ),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })


    const {
        rex_data_distribuidoras,
        rex_cargando_data_distribuidoras
    } = useSelector(({distribuidoras}) => distribuidoras)

    const columns = [
        {
            title: 'Código Distribuidor',
            dataIndex: 'codigo_dt',
            ...getColumnSearchProps('codigo_dt', 'código distribuidora'),
            sorter: (a, b) => a.codigo_dt ? a.codigo_dt - b.codigo_dt : false,
            showSorterTooltip: false,
            render: (codigo_dt) => <div title={codigo_dt}>{codigo_dt}</div>,
            width: '130px'
        },
        {
            title: 'Región',
            dataIndex: 'region',
            ...getColumnSearchProps('region', 'región'),
            sorter: (a, b) => a.region ? a.region.localeCompare(b.region) : false,
            showSorterTooltip: false,
            render: (region) => <div title={region}>{region}</div>,
            width: '120px'
        },
        {
            title: 'Supervisor',
            dataIndex: 'supervisor',
            ...getColumnSearchProps('supervisor', 'supervisor'),
            sorter: (a, b) => a.supervisor ? a.supervisor.localeCompare(b.supervisor) : false,
            showSorterTooltip: false,
            render: (supervisor) => <div title={supervisor}>{supervisor}</div>,
            width: '120px'
        },
        {
            title: 'Localidad',
            dataIndex: 'localidad',
            ...getColumnSearchProps('localidad', 'localidad'),
            sorter: (a, b) => a.localidad ? a.localidad.localeCompare(b.localidad) : false,
            showSorterTooltip: false,
            render: (localidad) => <div title={localidad}>{localidad}</div>,
            width: '120px'
        },
        {
            title: 'NombreDistribuidor',
            dataIndex: 'nomb_dt',
            ...getColumnSearchProps('nomb_dt', 'nombre distribuidor'),
            sorter: (a, b) => a.nomb_dt ? a.nomb_dt.localeCompare(b.nomb_dt) : false,
            showSorterTooltip: false,
            render: (nomb_dt) => <div title={nomb_dt}>{nomb_dt}</div>,
            width: '120px'
        },
        {
            title: 'CheckVentas',
            dataIndex: 'check_venta',
            ...getColumnSearchProps('check_venta', 'check venta'),
            sorter: (a, b) => a.check_venta ? a.check_venta.localeCompare(b.check_venta) : false,
            showSorterTooltip: false,
            render: (check_venta) => <div title={check_venta}>{check_venta}</div>,
            width: '120px'
        },
        {
            title: 'Nombre Cliente',
            dataIndex: 'nomb_cliente',
            ...getColumnSearchProps('nomb_cliente', 'nombre cliente'),
            sorter: (a, b) => a.nomb_cliente ? a.nomb_cliente.localeCompare(b.nomb_cliente) : false,
            showSorterTooltip: false,
            render: (nomb_cliente) => <div title={nomb_cliente}>{nomb_cliente}</div>,
            width: '120px'
        },
        {
            title: 'Latitud',
            dataIndex: 'latitud',
            ...getColumnSearchProps('latitud', 'latitud'),
            sorter: (a, b) => a.latitud.localeCompare(b.latitud),
            showSorterTooltip: false,
            render: (latitud) => <div title={isNaN(latitud) ? '' : latitud}>{isNaN(latitud) ? '' : latitud}</div>,
            width: '120px'
        },
        {
            title: 'Longitud',
            dataIndex: 'longitud',
            ...getColumnSearchProps('longitud', 'longitud'),
            sorter: (a, b) => a.longitud ? a.longitud - b.longitud : false,
            showSorterTooltip: false,
            render: (longitud) => <div title={isNaN(longitud) ? '' : longitud}>{isNaN(longitud) ? '' : longitud}</div>,
            width: '120px'
        },
        {
            title: 'Oficina2',
            dataIndex: 'oficina_two',
            ...getColumnSearchProps('oficina_two', 'oficina 2'),
            sorter: (a, b) => a.oficina_two ? a.oficina_two.localeCompare(b.oficina_two) : false,
            showSorterTooltip: false,
            render: (oficina_two) => <div title={oficina_two}>{oficina_two}</div>,
            width: '120px'
        },
        {
            title: 'Subcanal',
            dataIndex: 'subcanal',
            ...getColumnSearchProps('subcanal', 'subcanal'),
            sorter: (a, b) => a.subcanal ? a.subcanal.localeCompare(b.subcanal) : false,
            showSorterTooltip: false,
            render: (subcanal) => <div title={subcanal}>{subcanal}</div>,
            width: '120px'
        },
        {
            title: 'Sap Solicitante',
            dataIndex: 'sap_solicitante',
            ...getColumnSearchProps('sap_solicitante', 'sap solicitante'),
            sorter: (a, b) => a.sap_solicitante ? a.sap_solicitante.localeCompare(b.sap_solicitante) : false,
            showSorterTooltip: false,
            render: (sap_solicitante) => <div title={sap_solicitante}>{sap_solicitante}</div>,
            width: '120px'
        },
        {
            title: 'Sap Destinatario',
            dataIndex: 'sap_destinatario',
            ...getColumnSearchProps('sap_destinatario', 'sap destinatario'),
            sorter: (a, b) => a.sap_destinatario ? a.sap_destinatario.localeCompare(b.sap_destinatario) : false,
            showSorterTooltip: false,
            render: (sap_destinatario) => <div title={sap_destinatario}>{sap_destinatario}</div>,
            width: '120px'
        },
        {
            title: 'Diferencial',
            dataIndex: 'diferencial',
            ...getColumnSearchProps('diferencial', 'diferencial'),
            sorter: (a, b) => a.diferencial ? a.diferencial.localeCompare(b.diferencial) : false,
            showSorterTooltip: false,
            render: (diferencial) => <div title={diferencial}>{diferencial}</div>,
            width: '120px'
        },
        {
            title: 'Canal Atención',
            dataIndex: 'canal_atencion',
            ...getColumnSearchProps('canal_atencion', 'canal atención'),
            sorter: (a, b) => a.canal_atencion ? a.canal_atencion.localeCompare(b.canal_atencion) : false,
            showSorterTooltip: false,
            render: (canal_atencion) => <div title={canal_atencion}>{canal_atencion}</div>,
            width: '120px'
        },
        {
            title: 'Cód.Solicitante',
            dataIndex: 'cod_solicitante',
            ...getColumnSearchProps('cod_solicitante', 'código solicitante'),
            sorter: (a, b) => a.cod_solicitante? a.cod_solicitante - b.cod_solicitante : false,
            showSorterTooltip: false,
            render: (cod_solicitante) => <div title={cod_solicitante}>{cod_solicitante}</div>,
            width: '120px'
        },
        {
            title: 'Cód.Destinatario',
            dataIndex: 'cod_destinatario',
            ...getColumnSearchProps('cod_destinatario', 'código destinatario'),
            sorter: (a, b) => a.cod_destinatario ? a.cod_destinatario - b.cod_destinatario : null,
            showSorterTooltip: false,
            render: (cod_destinatario) => <div title={cod_destinatario}>{cod_destinatario}</div>,
            width: '120px'
        },
        {
            title: 'Canal Trade',
            dataIndex: 'canal_trade',
            ...getColumnSearchProps('canal_trade', 'canal trade'),
            sorter: (a, b) => a.canal_trade ? a.canal_trade.localeCompare(b.canal_trade): false,
            showSorterTooltip: false,
            render: (canal_trade) => <div title={canal_trade}>{canal_trade}</div>,
            width: '120px'
        },
        {
            title: 'Fec. Actualización',
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha actualización'),
            sorter: (a, b) => {
                const creacionA = a.created_at || ''
                const creacionB = b.created_at || ''
                return creacionA.localeCompare(creacionB)
            },
            showSorterTooltip: false,
            render: (created_at) => <div title={created_at}>{moment(created_at).format('DD/MM/YYYY')}</div>,
            width: '120px'
        }
    ]

    const ObtenerDistribuidora = async () => {
        await dispatch(ObtenerDataDistribuidorasReducer())
    }

    useEffect(() => {
        if(rex_data_distribuidoras.length == 0){
            ObtenerDistribuidora()
        }
    }, [])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} style={{marginRight:'10px'}}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Title level={4} style={{marginBottom: 0}}>Distribuidoras</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerDistribuidora()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_distribuidoras.length)} registros</div>
                    </div>
                    <Table

                        pagination={{
                            position: ["topRight", "none"],
                            pageSize : 15
                        }}
                        scroll={{
                            x: '1500px',
                            y: '350px',
                        }}
                        loading={rex_cargando_data_distribuidoras}
                        columns={columns} 
                        dataSource={rex_data_distribuidoras} 
                        className="Table-Home"
                        size='small'
                    />
                </Card>
            </Card>
        </div>
    )
}

export default Distribuidoras