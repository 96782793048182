import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Card, Button, Input, Spin, Form, Row, Col } from 'antd'
import {
    GetDataAdminPlatformReducer,
    CreateUpdateAdminPlatformReducer,
    ChangeMainTextReducer,
} from '../../../Redux/Actions/Administracion/AdminPlatform/AdminPlatform'
import {
    LoadingOutlined,
    ArrowLeftOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import '../../../Styles/Components/Administracion/Administrador/Usuarios.css'

const AdminPlatform = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography
    const [ loadingAdminPlatform, setLoadingAdminPlatform ] = useState(false)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const {
        rex_data_admin_platform,
        rex_loading_admin_platform,
    } = useSelector(({adminPlatform}) => adminPlatform)

    const CreateUpdateAdminPlatform = async () => {
        setLoadingAdminPlatform(true)
        const response = await dispatch(CreateUpdateAdminPlatformReducer())
        if(response){
            setLoadingAdminPlatform(false)
        }
    }
    
    useEffect(() => {
        dispatch(GetDataAdminPlatformReducer())
    },[])

    return (
        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Administrador de Plataforma</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <Form
                        layout='vertical'
                        onFinish={() => CreateUpdateAdminPlatform()}
                        autoComplete='off'
                        style={{height:'100%',justifyContent:'space-between'}}
                    >
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    label='Titulo Principal:'
                                >
                                    <Spin 
                                        indicator={<LoadingOutlined spin />} 
                                        size="small"
                                        spinning={rex_loading_admin_platform}
                                    >
                                        <Input
                                            value={rex_data_admin_platform?.main_text}
                                            onChange={(e) => dispatch(ChangeMainTextReducer(e.target.value, "main_text"))}
                                        />
                                    </Spin>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label='Texto Secundario:'
                                >
                                    <Spin 
                                        indicator={<LoadingOutlined spin />} 
                                        size="small"
                                        spinning={rex_loading_admin_platform}
                                    >
                                        <Input
                                            value={rex_data_admin_platform?.secondary_text}
                                            onChange={(e) => dispatch(ChangeMainTextReducer(e.target.value, "secondary_text"))}
                                        />
                                    </Spin>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button 
                            type="primary" 
                            ghost
                            htmlType="submit"
                            className='Button-Action-Type-User'
                            style={{margin: 0}}
                        >
                            { loadingAdminPlatform
                            ? <LoadingOutlined />
                            : <>
                                <SaveOutlined style={{marginRight:'6px'}}/>
                                    Guardar
                            </>
                            }
                        </Button>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default AdminPlatform