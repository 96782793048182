import React, {useState} from 'react'
import { Modal } from 'antd'

const ModalNotificacionesError = (props) => {

    const isModalOpen = props.isModalOpen
    const setIsModalOpen = props.setIsModalOpen
    const data = props.data

    return (
        <Modal 
            title="Notificaciones" 
            open={isModalOpen} 
            onCancel={() => setIsModalOpen(null)}
            footer={null}
        >
            <div>
                {
                    Array.isArray(data)
                    ? data.map((notf, pos) => {
                        return(
                            <div
                                style={{
                                    paddingLeft:'10px'
                                }}
                            >
                                <div
                                    className='Titulo-Detalle-Notificacion-Upload'
                                >
                                    {pos+1}) Se encontraron las siguientes observaciones en la columna <b>{notf.columna}</b>:
                                </div>
                                {
                                    notf.notificaciones.map((detalle, posdet) => {
                                        return (
                                            <div className='Detalle-Notificacion-Upload'>
                                                <div
                                                    style={{
                                                        paddingLeft:'20px'
                                                    }}
                                                >
                                                {
                                                    "- "+detalle.msg
                                                }, en las siguientes lineas: {
                                                    detalle.rows.map((row, pos) => {
                                                        return (
                                                            <b>
                                                                {
                                                                    pos >= 6
                                                                    ?pos == 6
                                                                        ?<span>etc</span>
                                                                        :null
                                                                    :<span>{row}, </span>
                                                                }
                                                            </b>
                                                        )
                                                    })
                                                }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                    : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <b style={{color:'#1677ff'}}>{data}</b>:
                        </div>
                    )
                }
            </div>
        </Modal>
    )
}

export default ModalNotificacionesError