import React, {useEffect, useState} from 'react'
import { Typography, Table, Card, Button, Popconfirm, Input, Tooltip, Pagination, Skeleton, Select, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {
    FilterOutlined,
    EditOutlined,
    ReloadOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons'
import IconPaquete from '../../../Assets/Iconos/paquete.png'
import {
    ObtenerNoHomologadosReducer,
    ObtenerMasterProductosReducer,
    RegistrarHomologacionReducer,
    SelectProductsNoHomologacionReducer,
    ActualizarPaginaTablaNoHomologados,
    ActualizarFiltroOrdenNoHomologados,
    ActualizarFiltroInputNoHomologados
} from '../../../Redux/Actions/Homologaciones/NoHomologados/NoHomologados'
import { Link, useNavigate } from 'react-router-dom'
import { ClearDataHomologadosNoHomologados } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import { funPermisosObtenidos } from '../../../Functions/funPermiso'

const NoHomologados = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [showCheckBoxTable, setShowCheckBoxTable] = useState(true)
    const [filtrarInputActividad, setFiltrarInputActividad ] = useState(false)
    const [showPopConfirmOtros, setShowPopConfirmOtros] = useState(false)
    const [confirmLoadingPopConfirm, setConfirmLoadingPopConfirm] = useState(false)

    const {
        rex_data_user_permissions
    } = useSelector(({login}) => login)

    const {
		rex_data_no_homologados,
        rex_data_master_productos,
        rex_pagina_tabla_data_no_homologados,
        rex_filtro_orden_data_no_homologados,
        rex_filtro_input_data_no_homologados,
        rex_filtro_conexiones_no_homologados
    } = useSelector(({noHomologados}) => noHomologados)
    const {
        rex_total_no_homologados,
        rex_cargando_data_no_homologados,
    } = useSelector(({asignarNoHomologados}) => asignarNoHomologados)

    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${columnName}`}
                    value={rex_filtro_input_data_no_homologados[dataIndex]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                        dispatch(ActualizarFiltroInputNoHomologados(dataIndex, e.target.value))
                        setFiltrarInputActividad(true)
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined 
                    style={{color:rex_filtro_input_data_no_homologados[dataIndex]?.length > 0 ? 'blue':'gray'}}
                    />),
    })

    const columnTitle = (nameColumn, nameColumOrden) => (
        <div 
            style={{display:'flex', justifyContent:'space-between', cursor:'pointer', alignItems:'center'}}
            onClick={async () => {
                let orden
                if(rex_filtro_orden_data_no_homologados.orden == 'asc'){
                    orden = 'desc'
                }else if(rex_filtro_orden_data_no_homologados.orden == 'desc'){
                    orden = null
                    nameColumOrden = 's_ytd'
                }else{
                    orden = 'asc'
                }
                dispatch(ActualizarFiltroOrdenNoHomologados(nameColumOrden, orden))
            }}
        >
            {
                nameColumOrden == 'index'
                ? <div></div>
                :null
            }
            <span title={nameColumn}>{nameColumn}</span>
            <div style={{display : 'flex', flexDirection:'column'}}>
                <CaretUpOutlined 
                    style={{
                        color: 
                        rex_filtro_orden_data_no_homologados.column == nameColumOrden && rex_filtro_orden_data_no_homologados.orden == 'asc' ? 'blue' : 'gray',
                        fontSize: '9px'
                    }}
                />
                <CaretDownOutlined
                    style={{
                        color :
                        ( rex_filtro_orden_data_no_homologados.column == nameColumOrden &&  rex_filtro_orden_data_no_homologados.orden == 'desc')
                        || (nameColumOrden == 's_ytd' && rex_filtro_orden_data_no_homologados.orden == null)
                        ? 'blue' : 'gray',
                        fontSize: '9px'
                    }}
                />
            </div>
        </div>
    )

    const parseNumber = (value) => {

        const number = parseFloat(value);
        const numberFormated = number.toLocaleString("es-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        
        return numberFormated
    }

    const columns = [
        {
            title: columnTitle('#', 'index'),
            dataIndex: 'index',
            render: (_, record) => 
            <div 
                title={ record.index ? record.index : '-' }
            >
                { record.index ? record.index : '-' }
            </div>,
            width: '50px',
            align:'center'
        },
        Table.SELECTION_COLUMN,
        {
            title: columnTitle('ATTR 1', 'attr_1'),
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1', 'attr_1'),
            render: (_, record) => 
            <div 
                title={record.masterclientes_grow?.attr_1 ? record.masterclientes_grow?.attr_1 : '-'}
            >
                {record.masterclientes_grow?.attr_1 ? record.masterclientes_grow?.attr_1 : '-'}
            </div>,
            width: '90px'
        },
        {
            title: columnTitle('ZONE', 'zone'),
            dataIndex: 'zone',
            ...getColumnSearchProps('zone', 'zone'),
            render: (_, record) => 
            <div 
                title={record.masterclientes_grow?.zone ? record.masterclientes_grow?.zone : '-'}
            >
                {record.masterclientes_grow?.zone ? record.masterclientes_grow?.zone : '-'}
            </div>,
            width: '80px'
        },
        {
            title: columnTitle('REGIONAL RBA', 'regional_gba'),
            dataIndex: 'regional_gba',
            ...getColumnSearchProps('regional_gba', 'regional_gba'),
            render: (_, record) => 
            <div 
                title={record.masterclientes_grow?.regional_gba ? record.masterclientes_grow?.regional_gba : '-'}
            >
                {record.masterclientes_grow?.regional_gba ? record.masterclientes_grow?.regional_gba : '-'}
            </div>,
            width: '130px'
        },
        {
            title: columnTitle('CLIENTE HML', 'cliente_hml'),
            dataIndex: 'cliente_hml',
            ...getColumnSearchProps('cliente_hml', 'nombre distribuidora'),
            render: (_, record) => 
                    <div title={record.cliente_hml}>{record.cliente_hml ? record.cliente_hml: '-'}
                    </div>,
            width: '130px',

        },
        {
            title: columnTitle('SUCURSAL HML', 'client_sub_hml'),
            dataIndex: 'client_sub_hml',
            ...getColumnSearchProps('client_sub_hml', 'sucursal hml'),
            render: (_, record) => 
                    <div title={record.masterclientes_grow?.client_sub_hml}>{record.masterclientes_grow.client_sub_hml ? record.masterclientes_grow.client_sub_hml: '-'}
                    </div>,
            width: '130px',

        },
        {
            title: columnTitle('COD SKU DT', 'codigo_producto'),
            dataIndex: 'codigo_producto',
            ...getColumnSearchProps('codigo_producto', 'código producto'),
            render: (codigo_producto) => <div style={{textAlign: 'end'}} title={codigo_producto}>{codigo_producto}</div>,
            width: '100px'
        },
        {
            title: columnTitle('SKU DT', 'descripcion_producto'),
            dataIndex: 'descripcion_producto',
            ...getColumnSearchProps('descripcion_producto', 'descripción producto'),
            render: (descripcion_producto) => <div title={descripcion_producto}>{descripcion_producto}</div>,
            width: '190px'
        },
        {
            title: columnTitle('DESDE', 'desde'),
            dataIndex: 'desde',
            ...getColumnSearchProps('desde', 'desde'),
            render: (desde) => <div title={desde}>{desde}</div>,
            width: '80px'
        },
        {
            title: columnTitle('S/. YTD', 's_ytd'),
            dataIndex: 's_ytd',
            ...getColumnSearchProps('s_ytd', 'S/. YTD'),
            render: (s_ytd) => {
                s_ytd = parseInt(s_ytd)
                return (
                    <div style={{textAlign: 'end'}} title={isNaN(s_ytd.toLocaleString()) ? '0' : parseNumber(s_ytd)}>
                        {isNaN(s_ytd) ? '0' : parseNumber(s_ytd)}
                    </div>
                )
            },
            width: '75px'
        },
        {
            title: columnTitle('S/. MTD', 's_mtd'),
            dataIndex: 's_mtd',
            ...getColumnSearchProps('s_mtd', 'S/. MTD'),
            render: (s_mtd) => {
                s_mtd  = parseInt(s_mtd)
                return(
                    <div style={{textAlign: 'end'}} title={isNaN(s_mtd.toLocaleString()) ? '0' : parseNumber(s_mtd)}>
                        {isNaN(s_mtd) ? '0' : parseNumber(s_mtd)}
                    </div>
                )
            },
            width: '75px'
        },
        {
            title: 'ACCIONES',
            dataIndex: 'actions',
            render: (data, info) =>  
            <div style={{display:'flex',alignItems:'center', columnGap: '5px'}}>
                <Link to={`/asignar-no-homologados/${info.id}/no-combo`}>
                    <Tooltip title="Editar">
                        <EditOutlined 
                            className="Icon-Edit-Not-Homologaciones" 
                            onClick={() => dispatch(SelectProductsNoHomologacionReducer(info))}
                        />
                    </Tooltip>
                </Link>
                {
                    funPermisosObtenidos(
                        rex_data_user_permissions, 'modulo.skunohomologado.editarcombo',
                        <Link to={`/asignar-no-homologados/${info.id}/combo`}>
                            <Tooltip title="Editar Combo">
                                <img 
                                    style={{
                                        width: '20px',
                                        marginRight: '0'
                                    }}
                                    className="Icon-Edit-Not-Homologaciones" 
                                    src={IconPaquete} 
                                    onClick={() => dispatch(SelectProductsNoHomologacionReducer(info))}
                                />
                            </Tooltip>
                        </Link>
                    )
                }
            </div>
            ,
            width: '70px'
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys)
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    }

    
    const ObtenerDataFiltroNoHomologado = async () => {
        await dispatch(ObtenerNoHomologadosReducer(true))
        await dispatch(ClearDataHomologadosNoHomologados())
    }

    useEffect(() => {
        if(filtrarInputActividad){
            const timeOutFilter = setTimeout(() => {
                ObtenerDataFiltroNoHomologado()
            }, 1000)

            return () => clearTimeout(timeOutFilter)
        }
    },[rex_filtro_input_data_no_homologados])

    const ObtenerDataNoHomologados = async () => {
        await dispatch(ClearDataHomologadosNoHomologados())
        if(rex_data_no_homologados.length == 0){
            await dispatch(ObtenerNoHomologadosReducer(true))
        }
        if(rex_data_master_productos.length == 0){
            await dispatch(ObtenerMasterProductosReducer())
        }
    }

    useEffect(() => {
        ObtenerDataNoHomologados()
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Button 
                                type='primary' 
                                size='small' 
                                ghost 
                                onClick={() => navigate(-1)} 
                                className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">SKU (No Homologado)</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>

                        <div style={{display:'flex'}}>
                            <Space>

                                <Button 
                                    style={{display:'flex', alignItems:'center'}} size='small'
                                    onClick={async ()=> {
                                        await dispatch(ObtenerNoHomologadosReducer(true))
                                        await dispatch(ObtenerMasterProductosReducer())
                                    }}    
                                >
                                    <ReloadOutlined
                                        style={{fontSize:'14px', marginRight: '4px',color:'#1677ff'}}
                                    />Recargar
                                </Button>
                                <Popconfirm
                                    title="Confirmar"
                                    description="Esta seguro de enviar los productos seleccionados a otros"
                                    open={showPopConfirmOtros}
                                    onConfirm={async () => {
                                        let products_selecteds = []
                                        rex_data_no_homologados.forEach(rex_data => {
                                            if(selectedRowKeys.includes(rex_data.id)){
                                                products_selecteds.push(rex_data.id)
                                            }
                                        });
                                        await dispatch(RegistrarHomologacionReducer(0, 1, true, products_selecteds))
                                        setSelectedRowKeys([])
                                        setShowCheckBoxTable(false)
                                        setTimeout(() => {
                                            setShowCheckBoxTable(true)
                                        }, 100);
                                        setShowPopConfirmOtros(!showPopConfirmOtros)
                                        
                                    }}
                                    okButtonProps={{ loading: confirmLoadingPopConfirm }}
                                    onCancel={() => {
                                        setShowPopConfirmOtros(!showPopConfirmOtros)
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        disabled={!selectedRowKeys.length > 0}
                                        className="Button-CheckBox"
                                        onClick={() => {
                                            setShowPopConfirmOtros(!showPopConfirmOtros)
                                        }}
                                        size='small'
                                        style={{
                                            // marginBottom:'-20px',
                                            width:'90px',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}
                                    >
                                        <span style={{fontSize :'11px'}}>HML MASIVO {selectedRowKeys.length > 0 && `(${selectedRowKeys.length})`}</span>
                                    </Button>
                                </Popconfirm>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por attr 1"
                                    onChange={(e) => {
                                        let value = e == undefined ? '' : e
                                        dispatch(ActualizarFiltroInputNoHomologados('attr_1',value))
                                        ObtenerDataFiltroNoHomologado()
                                    }}
                                    value={
                                        rex_filtro_input_data_no_homologados.attr_1
                                        ? rex_filtro_input_data_no_homologados.attr_1
                                        : null
                                    }
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    size='small'
                                    style={{width: '180px'}}
                                    options={rex_filtro_conexiones_no_homologados}
                                />
                                {/* <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por responsable"
                                    onChange={(e) => {
                                        let value = e == undefined ? '' : e
                                        dispatch(ActualizarFiltroInputNoHomologados('responsable',value))
                                        ObtenerDataFiltroNoHomologado()
                                    }}
                                    value={
                                        rex_filtro_input_data_no_homologados.responsable
                                        ? rex_filtro_input_data_no_homologados.responsable
                                        : null
                                    }
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    size='small'
                                    style={{width: '200px'}}
                                    // options={rex_filtro_conexiones_no_homologados}
                                    options={[{label: 'Trade/IC', value: 'Trade/IC'},{ label: 'SAC', value:'SAC'}]}
                                /> */}
                            </Space>
                        </div>

                        { rex_cargando_data_no_homologados && rex_data_no_homologados.length == 0
                        ? <Skeleton.Input active={true} size='default'/>
                        : <div style={{display:'flex', justifyContent:'end', columnGap: '5px'}}>
                            <Pagination
                                current={rex_pagina_tabla_data_no_homologados}
                                total={rex_total_no_homologados}
                                pageSize={20}
                                onChange={async (page, pageSize) => {
                                    dispatch(ActualizarPaginaTablaNoHomologados(page))
                                }}
                                size='small'
                            />
                            <div>Total: {Intl.NumberFormat('en-IN').format(rex_total_no_homologados)} registros</div>
                        </div>
                        }
                        
                    
                    </div>

                    { rex_cargando_data_no_homologados && rex_data_no_homologados.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                    : showCheckBoxTable == true
                    ?
                        <Table 
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 20
                            }}
                            scroll={{
                                y: '430px',
                            }}
                            rowSelection={showCheckBoxTable == true ?{
                                type: 'checkbox',
                                ...rowSelection,
                            }: {}}
                            columns={columns}
                            loading={rex_cargando_data_no_homologados}
                            dataSource={rex_data_no_homologados} 
                            className="Table-Home Table-Small" 
                            size='small'
                        />
                        :null
                    }
                </Card>
            </Card>
        </div>
    )
}

export default NoHomologados