import config from '../../../../config'
import { notification } from 'antd'
import {
    CARGANDO_DATA_TIPOS_USUARIOS,
    OBTENER_DATA_TIPOS_USUARIOS,
    OBTENER_DATA_TIPOS_USUARIOS_PERMISOS,
    OBTENER_DATA_TIPO_USUARIO,
    OBTENER_DATA_TIPO_USUARIO_NUEVO
} from '../../../../Constans/Administracion/Administrador/TiposUsuarios'

const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}

export const ObtenerDataTiposUsuariosReducer = () => async ( dispatch ) => {

    dispatch({
        type    : CARGANDO_DATA_TIPOS_USUARIOS,
        payload : true
    })

    await fetch(config.api_url+'manage/get-types-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_TIPOS_USUARIOS,
            payload: data.data
        })
        dispatch({
            type: OBTENER_DATA_TIPOS_USUARIOS_PERMISOS,
            payload: data.pems
        })
    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type    : CARGANDO_DATA_TIPOS_USUARIOS,
        payload : false
    })
}

export const ObtenerDataTipoUsuarioReducer = (tpuid) => async ( dispatch ) => {
    await fetch(config.api_url+'manage/get-type-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_tpuid: parseInt(tpuid)
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        dispatch({
            type: OBTENER_DATA_TIPO_USUARIO,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })
}

export const ObtenerDataTipoUsuarioNuevoReducer = () => async ( dispatch ) => {

    await fetch(config.api_url+'manage/get-type-user-new',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then( async res => {return res.json()})
    .then(data => {                

        dispatch({
            type: OBTENER_DATA_TIPO_USUARIO_NUEVO,
            payload: data.data
        })
    }).catch((error)=> {
        console.log(error)
    })
}

export const EditarPermisoTipoUsuarioReducer = (type, id_value, value) => async ( dispatch, getState ) => {

    const pemstpu = getState().tiposUsuarios.rex_data_tipo_usuario

    switch (type) {
        case 'pemid':
            let index_tpe
            pemstpu.permisos.forEach(tpe => {
                tpe.permisos.forEach( pem => {
                    if(pem['pemid'] == id_value){
                        pem['tiene_permiso'] = value
                        index_tpe = tpe.tpeid
                    }
                })
                if(tpe.tpeid == index_tpe){
                    const pems_true = tpe.permisos.filter(tpe => tpe.tiene_permiso == true && tpe.tpeid == index_tpe)
                    if(pems_true.length > 0){
                        tpe['todos_permisos'] = true
                    }else{
                        tpe['todos_permisos'] = false
                    }
                }
            })
            break;
        case 'tpuprivilegio':
            pemstpu['tpuprivilegio'] = value
            break;
        case 'tpunombre':
            pemstpu['tpunombre'] = value
            break;
        case 'tpeid':
            pemstpu.permisos.forEach(tpe => {
                if(tpe.tpeid == id_value){
                    tpe['todos_permisos'] = value
                    tpe.permisos.forEach( pem => {
                        pem['tiene_permiso'] = value
                    })
                }
            })
            break;
        case 'status':
            pemstpu['estid'] = value ? 1 : 2
            break;
        default:
          break
    }


    dispatch({
        type: OBTENER_DATA_TIPO_USUARIO,
        payload: pemstpu
    })
}

export const EditarTipoUsuarioReducer = () => async ( dispatch, getState ) => {

    let tpu = getState().tiposUsuarios.rex_data_tipo_usuario
    if(tpu.tpuprivilegio == "todo"){
        const perms = tpu.permisos.map(tp => {
            const perms_tp = tp.permisos.map(tp_per => ({ ...tp_per, tiene_permiso: false }))
            return { ...tp, permisos: perms_tp, todos_permisos: false }
        })

        tpu = {
            ...tpu,
            permisos: perms,
        }
    }

    let respuesta = false

    await fetch(config.api_url+'manage/edit-type-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_tpu: tpu
            }),
        }
    )
    .then(res => res.json())
    .then(data => {                
        if(data.response){
            dispatch(ObtenerDataTiposUsuariosReducer())
            notificacionServidor('success', data.message)
            respuesta = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return respuesta
}

export const CrearTipoUsuarioReducer = () => async ( dispatch, getState ) => {

    const tpu = getState().tiposUsuarios.rex_data_tipo_usuario_nuevo

    let respuesta = false

    await fetch(config.api_url+'manage/create-type-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_tpu: tpu
            }),
        }
    )
    .then(res => res.json())
    .then(data => {                
        if(data.response){
            dispatch(ObtenerDataTiposUsuariosReducer())
            notificacionServidor('success', data.message)
            respuesta = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return respuesta
}
export const EliminarTipoUsuarioReducer = (tpuid) => async ( dispatch, getState ) => {

    await fetch(config.api_url+'manage/delete-type-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usu_token'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_tpuid: tpuid
            }),
        }
    )
    .then(res => res.json())
    .then(data => {                
        if(data.response){
            dispatch(ObtenerDataTiposUsuariosReducer())
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}


export const EditarPermisoTipoUsuarioNuevoReducer = (type, id_value, value)  => async ( dispatch, getState ) => {

    const pemstpu = getState().tiposUsuarios.rex_data_tipo_usuario_nuevo

    switch (type) {
        case 'pemid':
            let index_tpe
            pemstpu.permisos.forEach(tpe => {
                tpe.permisos.forEach( pem => {
                    if(pem['pemid'] == id_value){
                        pem['tiene_permiso'] = value
                        index_tpe = tpe.tpeid
                    }
                })
                if(tpe.tpeid == index_tpe){
                    const pems_true = tpe.permisos.filter(tpe => tpe.tiene_permiso == true && tpe.tpeid == index_tpe)
                    if(pems_true.length > 0){
                        tpe['todos_permisos'] = true
                    }else{
                        tpe['todos_permisos'] = false
                    }
                }
            })
            break;
        case 'tpuprivilegio':
            pemstpu['tpuprivilegio'] = value
            break;
        case 'tpunombre':
            pemstpu['tpunombre'] = value
            break;
        case 'tpeid':
            pemstpu.permisos.forEach(tpe => {
                if(tpe.tpeid == id_value){
                    tpe['todos_permisos'] = value
                    tpe.permisos.forEach( pem => {
                        pem['tiene_permiso'] = value
                    })
                }
            })
            break;
        case 'status':
            pemstpu['estid'] = value ? 1 : 2
            break;
        default:
          break
    }

    dispatch({
        type: OBTENER_DATA_TIPO_USUARIO_NUEVO,
        payload: pemstpu
    })
}
