import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button, Pagination, Skeleton, Popconfirm } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { 
    DeleteMaestraProductosGrowReducer,
    ObtenerMaestraProductosGrowReducer,
} from '../../../Redux/Actions/Administracion/MaestraGrow/MaestraGrow'
import moment from 'moment'
import { funPermisosObtenidos } from '../../../Functions/funPermiso'

const MaestraProductosGrow = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const [ pageTable, setPageTable ] = useState(1)

    const {
        rex_data_user_permissions
    } = useSelector(({login}) => login)
    
    const getColumnSearchProps = (dataIndex, text) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${text}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined />),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })

    const columns = [
        {
            title: 'Código material',
            dataIndex: 'codigo_material',
            ...getColumnSearchProps('codigo_material', 'Código material'),
            sorter: (a, b) => a.codigo_material ? a.codigo_material.localeCompare(b.codigo_material) : false,
            showSorterTooltip: false,
            render: (codigo_material) => <div title={codigo_material}>{codigo_material}</div>,
            width: '130px'
        },
        {
            title: 'Material',
            dataIndex: 'material',
            ...getColumnSearchProps('material', 'Material'),
            sorter: (a, b) => a.material ? a.material.localeCompare(b.material) : false,
            showSorterTooltip: false,
            render: (material) => <div title={material}>{material}</div>,
            width: '130px'
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            ...getColumnSearchProps('categoria', 'Categoría'),
            sorter: (a, b) => a.categoria ? a.categoria.localeCompare(b.categoria) : false,
            showSorterTooltip: false,
            render: (categoria) => <div title={categoria}>{categoria}</div>,
            width: '130px'
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'subcategoria',
            ...getColumnSearchProps('subcategoria', 'Sub Categoría'),
            sorter: (a, b) => a.subcategoria ? a.subcategoria.localeCompare(b.subcategoria) : false,
            showSorterTooltip: false,
            render: (subcategoria) => <div title={subcategoria}>{subcategoria}</div>,
            width: '130px'
        },
        {
            title: 'Formato',
            dataIndex: 'formato',
            ...getColumnSearchProps('formato', 'Formato'),
            sorter: (a, b) => a.formato ? a.formato.localeCompare(b.formato): false,
            showSorterTooltip: false,
            render: (formato) => <div title={formato}>{formato}</div>,
            width: '130px'
        },
        {
            title: 'Codigo EAN',
            dataIndex: 'codigo_ean',
            ...getColumnSearchProps('codigo_ean', 'Codigo EAN'),
            sorter: (a, b) => a.codigo_ean ? a.codigo_ean.localeCompare(b.codigo_ean) : false,
            showSorterTooltip: false,
            render: (codigo_ean) => <div title={codigo_ean}>{codigo_ean}</div>,
            width: '130px'
        },
        {
            title: 'Attr 1',
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1', 'Attr 1'),
            sorter: (a, b) => a.attr_1 ? a.attr_1.localeCompare(b.attr_1) : false,
            showSorterTooltip: false,
            render: (attr_1) => <div title={attr_1}>{attr_1}</div>,
            width: '130px'
        },
        {
            title: 'Attr 2',
            dataIndex: 'attr_2',
            ...getColumnSearchProps('attr_2', 'Attr 2'),
            sorter: (a, b) => a.attr_2 ? a.attr_2.localeCompare(b.attr_2) : false,
            showSorterTooltip: false,
            render: (attr_2) => <div title={attr_2}>{attr_2}</div>,
            width: '130px'
        },
        {
            title: 'Attr 3',
            dataIndex: 'attr_3',
            ...getColumnSearchProps('attr_3', 'Attr 3'),
            sorter: (a, b) => a.attr_3 ? a.attr_3.localeCompare(b.attr_3) : false,
            showSorterTooltip: false,
            render: (attr_3) => <div title={attr_3}>{attr_3}</div>,
            width: '130px'
        },
        {
            title: 'Attr 4',
            dataIndex: 'attr_4',
            ...getColumnSearchProps('attr_4', 'Attr 4'),
            sorter: (a, b) => a.attr_4 ? a.attr_4.localeCompare(b.attr_4) : false,
            showSorterTooltip: false,
            render: (attr_4) => <div title={attr_4}>{attr_4}</div>,
            width: '130px'
        },
        {
            title: 'Attr 5',
            dataIndex: 'attr_5',
            ...getColumnSearchProps('attr_5', 'Attr 5'),
            sorter: (a, b) => a.attr_5 ? a.attr_5.localeCompare(b.attr_5) : false,
            showSorterTooltip: false,
            render: (attr_5) => <div title={attr_5}>{attr_5}</div>,
            width: '130px'
        },
        {
            title: 'Attr 6',
            dataIndex: 'attr_6',
            ...getColumnSearchProps('attr_6', 'Attr 6'),
            sorter: (a, b) => a.attr_6 ? a.attr_6.localeCompare(b.attr_6) : false,
            showSorterTooltip: false,
            render: (attr_6) => <div title={attr_6}>{attr_6}</div>,
            width: '130px'
        },
        {
            title: 'EQ CJS',
            dataIndex: 'eq_cjs',
            ...getColumnSearchProps('eq_cjs', 'EQ CJS'),
            sorter: (a, b) => a.eq_cjs ? a.eq_cjs - b.eq_cjs : false,
            showSorterTooltip: false,
            render: (eq_cjs) => <div title={eq_cjs}>{eq_cjs}</div>,
            width: '130px'
        },
        {
            title: 'EQ TON',
            dataIndex: 'eq_ton',
            ...getColumnSearchProps('eq_ton', 'EQ TON'),
            sorter: (a, b) => a.eq_ton ? a.eq_ton - b.eq_ton : false,
            showSorterTooltip: false,
            render: (eq_ton) => <div title={eq_ton}>{eq_ton}</div>,
            width: '130px'
        },
        {
            title: 'EQ GSU',
            dataIndex: 'eq_gsu',
            ...getColumnSearchProps('eq_gsu', 'EQ GSU'),
            sorter: (a, b) => a.eq_gsu ? a.eq_gsu - b.eq_gsu : false,
            showSorterTooltip: false,
            render: (eq_gsu) => <div title={eq_gsu}>{eq_gsu}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES NIV',
            dataIndex: 'eq_soles_niv',
            ...getColumnSearchProps('eq_soles_niv', 'EQ SOLES NIV'),
            sorter: (a, b) => a.eq_soles_niv ? a.eq_soles_niv - b.eq_soles_niv : false,
            showSorterTooltip: false,
            render: (eq_soles_niv) => <div title={eq_soles_niv}>{eq_soles_niv}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES NET',
            dataIndex: 'eq_soles_net',
            ...getColumnSearchProps('eq_soles_net', 'EQ SOLES NET'),
            sorter: (a, b) => a.eq_soles_net ? a.eq_soles_net - b.eq_soles_net : false,
            showSorterTooltip: false,
            render: (eq_soles_net) => <div title={eq_soles_net}>{eq_soles_net}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES INVOICE',
            dataIndex: 'eq_soles_invoice',
            ...getColumnSearchProps('eq_soles_invoice', 'EQ SOLES INVOICE'),
            sorter: (a, b) => a.eq_soles_invoice ? a.eq_soles_invoice - b.eq_soles_invoice : false,
            showSorterTooltip: false,
            render: (eq_soles_invoice) => <div title={eq_soles_invoice}>{eq_soles_invoice}</div>,
            width: '130px'
        },
        {
            title: 'EQ SOLES DSCTO',
            dataIndex: 'eq_soles_dscto',
            ...getColumnSearchProps('eq_soles_dscto', 'EQ SOLES DSCTO'),
            sorter: (a, b) => a.eq_soles_dscto ? a.eq_soles_dscto - b.eq_soles_dscto : false,
            showSorterTooltip: false,
            render: (eq_soles_dscto) => <div title={eq_soles_dscto}>{eq_soles_dscto}</div>,
            width: '130px'
        },
        {
            title: 'Fec. Actualización',
            dataIndex: 'created_at',
            ...getColumnSearchProps('created_at', 'fecha actualización'),
            sorter: (a, b) => {
                const creacionA = a.created_at || ''
                const creacionB = b.created_at || ''
                return creacionA.localeCompare(creacionB)
            },
            showSorterTooltip: false,
            render: (created_at) => <div title={created_at?created_at:''}>{created_at? moment(created_at).format('DD/MM/YYYY') : ''}</div>,
            width: '120px'
        },
        {
            title: 'Acciones',
            dataIndex: 'Acciones',
            render: (_, record) => 
                <Popconfirm
                    title='Eliminar archivo'
                    description='Está seguro de eliminar el registro?'
                    okText='Confirmar'
                    cancelText='Cancelar'
                    placement="bottomRight"
                    onConfirm={() => dispatch(DeleteMaestraProductosGrowReducer(record.id, record.codigo_material))}
                >
                    <DeleteOutlined
                        title='Eliminar'
                        style={{fontSize:'15px', color:'red', cursor:'pointer', margin:'0 2px'}}
                    />
                </Popconfirm>,
            fixed: 'right',
            width: '70px',
        },
    ]

    const ObtenerProductosGrow = async () => {
        await dispatch(ObtenerMaestraProductosGrowReducer())
    }

    const {
        rex_data_maestra_productos_grow,
        rex_cargando_maestra_productos_grow,
        rex_feha_actualizacion_maestra_productos_grow
    } = useSelector(({maestraGrow}) => maestraGrow)


    useEffect(() => {
        if(rex_data_maestra_productos_grow.length == 0){
            ObtenerProductosGrow()
        }
    }, [])

    return (

        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Title className="Title-Module">
                        Maestra de Productos Grow {rex_feha_actualizacion_maestra_productos_grow?.espfechaactualizacion
                            ? <span style={{fontSize: '12px'}}>{moment(rex_feha_actualizacion_maestra_productos_grow.espfechaactualizacion).format('DD/MM/YYYY hh:mm:a')}</span>
                            : <span style={{fontSize: '12px'}}>(09/06/2023 11:40 AM)</span>
                        }
                        </Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerProductosGrow()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        { rex_cargando_maestra_productos_grow && rex_data_maestra_productos_grow.length == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_maestra_productos_grow.length}
                                    pageSize={15}
                                    onChange={(page) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos_grow.length )} registros</div>
                            </div>
                        }
                        
                    </div>

                    { rex_cargando_maestra_productos_grow && rex_data_maestra_productos_grow.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                        
                    : <Table 
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 15,
                            current: pageTable,
                        }}
                        scroll={{
                            x: '1500px',
                            y: '450px',
                        }}
                        columns={columns.filter(cls => {
                            if(cls.title == "Acciones"){
                                const response_perms = funPermisosObtenidos(rex_data_user_permissions, 'master.products', cls)

                                if(response_perms){
                                    return true
                                }

                                return false 
                            }

                            return true
                        })}
                        dataSource={rex_data_maestra_productos_grow} 
                        className="Table-Home"
                        size='small'
                    />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraProductosGrow