import { useState } from 'react';
import { Button, Modal, Table, Input, Pagination } from 'antd';
import IconoSac from '../../Assets/Img/Status/iconoSac.png'
import IconoCerrar from '../../Assets/Iconos/iconoCerrar.png'
import '../../Styles/Routes/Status/Status.css'
import { useSelector } from 'react-redux';
import moment from 'moment'
import {
    FilterOutlined,
} from '@ant-design/icons'

const ModalDetailStatus = (props) => {

    const isModalOpen       = props.modalOpen
    const setIsModalOpen    = props.setModalOpen

    const handleOk = () => {
        setIsModalOpen(!isModalOpen);
    };
    const [pageTableModalStatus, setPageTableModalStatus] = useState(1)
    const [pageSizeTableModalStatus, setPageSizeTableModalStatus] = useState(20)
    const handleCancel = () => {
        setIsModalOpen(!isModalOpen);
    };

    const {
        data_estados_pendientes_distribuidoras_home
    } = useSelector(({status}) => status)

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        console.log(e.target.value)
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined style={{color:'#ffffff'}} />),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })
      
    const columns = [
        {
            title: 'N°',
            dataIndex: 'key',
            render: (id, record, index) => {
                ++index; 
                return <div className='Text-Table-Gray-Status'>{(pageTableModalStatus - 1) * pageSizeTableModalStatus + index }</div>
            },
            width:'30px',
            align : 'center'
        },
        {
            title: 'Zone',
            dataIndex: 'zone',
            ...getColumnSearchProps('zone'),
            sorter: (a, b) => {
                return a.zone ? a.zone.localeCompare(b.zone) : false
            },
            showSorterTooltip: false,
            render: (_, record) => (
                <div
                    title={record.masterclientes_grow?.zone} 
                    className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'} `}
                >
                    {record.masterclientes_grow? record.masterclientes_grow.zone : ''}
                </div>
            ),
            width:'70px',
            align : 'center'
        },
        {
            title: 'Regional GBA',
            dataIndex: 'regional_gba',
            ...getColumnSearchProps('regional_gba'),
            sorter: (a, b) => {
                return a.regional_gba ? a.regional_gba.localeCompare(b.regional_gba) : false
            },
            showSorterTooltip: false,
            render: (_, record) => (
                <div 
                    title={record.masterclientes_grow?.regional_gba}
                    className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'} `}
                >
                    {record.masterclientes_grow ? record.masterclientes_grow.regional_gba : ''}
                </div>
            ),
            width:'100px',
            align : 'center'
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente_hml',
            ...getColumnSearchProps('cliente_hml'),
            sorter: (a, b) => {
                return a.cliente_hml ? a.cliente_hml.localeCompare(b.cliente_hml) : false
            },
            showSorterTooltip: false,
            render: (_, record) => (
                            <div 
                            title={record.masterclientes_grow?.cliente_hml} 
                            className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'} `}
                            >
                                {record.masterclientes_grow ? record.masterclientes_grow.cliente_hml : ''}
                            </div>
                        ),
            width:'130px',
            align : 'center'
        },
        {
            title: 'Responsable',
            dataIndex: 'espresponsable',
            ...getColumnSearchProps('espresponsable'),
            sorter: (a, b) => a.espresponsable ? a.espresponsable.localeCompare(b.espresponsable) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                <div 
                    title={record.espresponsable}
                    className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'} `}
                >
                    {record.espresponsable}
                </div>
                ),
            width:'110px',
            align : 'center'
        },
        {
            title: 'Usuario',
            dataIndex: 'pernombrecompleto',
            ...getColumnSearchProps('pernombrecompleto'),
            sorter: (a, b) => a.pernombrecompleto ? a.pernombrecompleto.localeCompare(b.pernombrecompleto) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                <div 
                    title={record.perpersonas ? record.perpersonas.pernombrecompleto: ''}
                    className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'} `}
                >
                    {record.perpersonas ? record.perpersonas.pernombrecompleto: '-'}
                </div>
            ),
            width:'110px',
            align : 'center'
        },
        {
            title: 'Attr 1',
            dataIndex: 'attr_1',
            ...getColumnSearchProps('attr_1'),
            sorter: (a, b) => a.attr_1 ? a.attr_1.localeCompare(b.attr_1) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                        <div 
                            className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'}`}
                        >
                            {record.masterclientes_grow ? record.masterclientes_grow.attr_1 : ''}
                        </div>
                    ),
            width:'100px',
            align : 'center'
        },
        {
            title: 'DeadLine',
            dataIndex: 'deadline',
            ...getColumnSearchProps('deadline'),
            sorter: (a, b) => a.espfechaprogramado ? a.espfechaprogramado.localeCompare(b.espfechaprogramado) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                        <div className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'}`}>
                            {record.espfechaprogramado ? moment(record.espfechaprogramado).utc().format('DD/MM/YYYY'): '-'}
                        </div>
                    ),
            width:'85px',
            align : 'center'
        },
        {
            title: 'DeadLine actualizado',
            dataIndex: 'deadline_actualizado',
            ...getColumnSearchProps('deadline_actualizado'),
            sorter: (a, b) => a.espfechactualizacion ? a.espfechactualizacion.localeCompare(b.espfechactualizacion) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                        <div className={`${record.espmesretraso ? 'Text-Table-Red-Status': 'Text-Table-Gray-Status'}`}>
                            {
                                record.espfechactualizacion != null
                                ? moment(record.espfechactualizacion).utc().format('DD/MM/YYYY')
                                : '-'
                            }
                        </div>
            ),
            width:'90px',
            align : 'center'
        },
        {
            title: 'Días de retraso',
            dataIndex: 'dia_retraso',
            ...getColumnSearchProps('dia_retraso'),
            sorter: (a, b) => a.espdiaretraso ? a.espdiaretraso.localeCompare(b.espdiaretraso) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <span style={{fontSize:'10px', textAlign:'center', color: `${record.espdiaretraso > 0 ? 'red':'#1eec41'}`}}>{record.espdiaretraso} días</span>
                        <div style={{width:'15px', height:'7px', borderRadius:'10px', backgroundColor: `${record.espdiaretraso > 0 ? 'red':'#1eec41'}`}}></div>
                    </div>
                </div>
            ),
            width:'80px',
            align : 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ...getColumnSearchProps('status'),
            sorter: (a, b) => a.espfechactualizacion ? a.espfechactualizacion.localeCompare(b.espfechactualizacion) : false,
            showSorterTooltip: false,
            render: (_, record) => (
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{fontSize:'10px', textAlign:'center',  color: `${record.espfechactualizacion != null ? '#1eec41':'red'}`}}>
                        {`${record.espfechactualizacion != null ? 'Cargado':'Pendiente'}`}
                    </div>
                </div>
            ),
            width:'80px',
            align : 'center'
        },
    ]

    return (
        <Modal 
            title={<div style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={IconoSac} style={{width:'25px', height:'25px'}}/>
                <span className='Title-Modal-Status-Detail'>DTS (Sell Out)</span>
            </div>}
            open={isModalOpen}
            footer={null}
            centered={true}
            onOk={handleOk}
            width={2000}
            style={{borderRadius:'20px'}}
            onCancel={handleCancel}
            closeIcon={<img width='25px' src={IconoCerrar}/>}
            className='Modal-Status-Detail'
        >
            <div style={{display:'flex', justifyContent:'end'}}>
                <Pagination
                    className='Pagination-Modal-Status'
                    defaultCurrent={pageTableModalStatus}
                    size='small'
                    current={pageTableModalStatus}
                    pageSize={20}
                    total={data_estados_pendientes_distribuidoras_home.length}
                    onChange={(page, pageSize) => {
                        setPageTableModalStatus(page == 0? 1 : page)
                        setPageSizeTableModalStatus(pageSize)
                    }}
                />
                <div style={{textAlign: 'end', marginBottom: '3px', marginLeft:'5px'}}>Total: {Intl.NumberFormat('en-IN').format(data_estados_pendientes_distribuidoras_home.length)} registros</div>
            </div>
            <Table
                columns={columns}
                dataSource={data_estados_pendientes_distribuidoras_home}
                className="Table-Status Status-Detail"
                bordered={false}
                size='small'
				pagination={{
					defaultCurrent: pageTableModalStatus,
					current: pageTableModalStatus,
					pageSize: 20,
					position: ['topRight'],
				}}
            />
        </Modal>
    );
};
export default ModalDetailStatus;