import config from '../../../config'
import axios from 'axios';
import { notification } from 'antd'
import { 
    OBTENER_DATA_MASTER_PRODUCTOS, 
    OBTENER_DATA_NO_HOMOLOGADOS 
} from '../../../Constans/Homologaciones/NoHomologados/NoHomologados';
import { 
    OBTENER_DATA_HOMOLOGADOS
} from '../../../Constans/Homologaciones/Homologados/Homologados';
import { 
    MSGS_NOTIFICACIONES_UPLOAD_FILE,
    ERRORS_DT_MANUALS,
    ERRORS_SELL_IN,
    ERRORS_MASTER_PRODUCTS,
    ERRORS_MASTER_CLIENTS,
    ERRORS_MASTER_PRICE
} from '../../../Constans/UploadFile/UploadFile'


const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}

const notificationDistributors = (type, mensaje, grupomensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: grupomensaje.length > 0
                ?   <>
                        <div>{mensaje}</div>
                        <ul>
                            { grupomensaje.map(men => <li>{men}</li>) }
                        </ul>
                    </> 
                :   <>{mensaje}</>,
            duration: 10,
            style: { width: 500 }
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
            duration: 5,
        })
    }
}

// export const CargarNoHMLReducer = ( fileList, deleteData, titleFile, date ) => async ( dispatch, getState ) => {
//     const formData = new FormData();
//     const { 
//         rex_msgs_notificaciones
//     } = getState().uploadFile
    
//     fileList.forEach((file) => {
//         formData.append('carga_no_hml', file); // archivo es la referencia al archivo que deseas enviar
//     });

//     const actions_file = { process_data : true , delete_data : true}
//     formData.append('req_action_file', JSON.stringify(actions_file));
//     formData.append('req_type_file', titleFile);
//     formData.append('req_date_updated', date);
    
//     let rpta = true

//     const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
//     try {
//         const rquest = await axios.post(config.api_url+'carga-archivos/no-hml', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data',
//                 'usutoken'	   : localStorage.getItem('usutoken'),
//             },
//         })
        
//         if(rquest.status){
//             if(rquest.status == 200){

//                 rex_msgs_notificaciones.push({
//                     "titulo"  : "Notificación N°"+numero_notificacion,
//                     "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
//                     "respuesta" : rquest.data.respuesta,
//                     "data" : []
//                 })

//                 dispatch({
//                     type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
//                     payload : rex_msgs_notificaciones
//                 })
//             }else{
//                 rex_msgs_notificaciones.push({
//                     "titulo"  : "Notificación N°"+numero_notificacion,
//                     "mensaje" : "Ha ocurrido un error al actualizar",
//                     "respuesta" : false,
//                     "data" : []
//                 })

//                 dispatch({
//                     type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
//                     payload : rex_msgs_notificaciones
//                 })
//             }
//         }
        
//     } catch (error) {
//         console.error('Error al enviar el archivo:', error);
        
//         rpta = false
//         if(error.response){
//             if(error.response.data){

//                 const mensagges = error.response.data.messages_error
//                 const rquest = error.response

//                 rex_msgs_notificaciones.push({
//                     "titulo"  : "Notificación N°"+numero_notificacion,
//                     "mensaje" : rquest.data.message,
//                     "respuesta" : rquest.data.respuesta,
//                     "data" : rquest.data.notificaciones
//                 })

//                 dispatch({
//                     type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
//                     payload : rex_msgs_notificaciones
//                 })

//                 if(mensagges){
//                     let msg_error_html = ""
//                     mensagges.map((msg) => {
//                         msg_error_html+= '<div>'+msg+'</div>'
//                     })
//                 }else{
//                 }
//             }else{
//             }
//         }else{
//         }
//     }

//     return rpta
// }


export const CargarManualReducer = ( fileList, deleteData, titleFile, date ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    const { 
        rex_errors_dt_manuals,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('carga_manual', file); // archivo es la referencia al archivo que deseas enviar
    });

    const actions_file = { process_data : true , delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file));
    formData.append('req_type_file', titleFile);
    formData.append('req_date_updated', date);
    
    let rpta = true

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/dt-manuales', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        
        if(rquest.status){
            if(rquest.status == 200){
                // notificacionServidor('success', rquest.data.message)
                // dispatch({
                //     type    : OBTENER_DATA_MASTER_PRODUCTOS,
                //     payload : []
                // })
                // dispatch({
                //     type    : OBTENER_DATA_HOMOLOGADOS,
                //     payload : []
                // })
                // dispatch({
                //     type    : OBTENER_DATA_NO_HOMOLOGADOS,
                //     payload : []
                // })

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);
        
        rpta = false
        if(error.response){
            if(error.response.data){

                const mensagges = error.response.data.messages_error
                const rquest = error.response
                // dispatch({
                //     type    : ERRORS_DT_MANUALS,
                //     payload : [...rex_errors_dt_manuals, ...error.response.data.messages_error]
                // })

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                    // notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    // notificacionServidor('error', error.response.data.message)
                }
            }else{
                // notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            // notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }


    }

    return rpta
}

export const ValidateDtManualesDistributorsReducer = (fileList) => async (dispatch, getState) => {
    let response = true
    const formData = new FormData()
    
    fileList.forEach((file) => {
        formData.append('carga_manual', file)
    })

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/dt-manuales-distributors', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })
        console.log("Rquest", rquest)
    } catch (error) {
        const responseAxios = error.response
        if(responseAxios.status == 400){
            notificationDistributors('warning', responseAxios.data.message, responseAxios.data.message_distributors)
        }else if(responseAxios.status == 500){
            notificationDistributors('error', responseAxios.data.message)
        }
        response = false
    }

    return response
}

export const ActualizarYtdMtdReducer = () => async ( dispatch, getState ) => {

    let response = false

    await fetch(config.api_url+'actualizaciones/actualizar-ytd-mtd',
        {
            mode:'cors',
            method: 'GET',
        }
    )
    .then( async res => {return res.json()})
    .then(data => {           
        if(data.response){
            response = true
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}

export const UploadHomologacionComboReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    let { 
        rex_errors_master_products,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('hml_combo', file)
    })

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile);
    formData.append('req_plataforma', JSON.stringify(true))
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/upload-hml-combo', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(rquest.status){
            if(rquest.status == 200){ 
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.response,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                const rquest = error.response

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                }
            }
        }
    }

    return rpta
}

export const UploadHomologacionNoComboReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    let { 
        rex_errors_master_products,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('hml_no_combo', file)
    })

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile)
    formData.append('req_plataforma', JSON.stringify(true))
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/upload-hml-no-combo', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(rquest.status){
            if(rquest.status == 200){ 
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.response,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error)

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                const rquest = error.response

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                }
            }
        }
    }

    return rpta
}

export const CargarProductosReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    let { 
        rex_errors_master_products,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('maestra_producto', file); // archivo es la referencia al archivo que deseas enviar
    });

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile);
    formData.append('req_plataforma', JSON.stringify(true));
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        // const rquest = await axios.post(config.api_url+'carga-archivos/master-materiales', formData, {
        const rquest = await axios.post(config.api_url+'carga-archivos/master-productos-grow', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        //Numero de productos que no se pudieron eliminar por estar en uso
        const notDeletedProducts = rquest.data.notDeletedProducts;

        //Numero de productos nuevos creados
        const numberOfCreatedProducts = rquest.data.createdProducts;

        //Numero de productos eliminados
        const numberOfDeletedProducts = rquest.data.deletedProducts;

        if(rquest.status){
            if(rquest.status == 200){
                // notificacionServidor('success', rquest.data.message)    

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `Archivo de ${titleFile}: Se crearon ${numberOfCreatedProducts} nuevos registros
                    -- Se eliminaron ${numberOfDeletedProducts} registros -- No se pudieron eliminar ${notDeletedProducts} previos registros por estar en uso`,
                    "respuesta" : rquest.data.response,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                const rquest = error.response
                // dispatch({
                //     type    : ERRORS_MASTER_PRODUCTS,
                //     payload : [...rex_errors_master_products, ...error.response.data.messages_error]
                // })

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                    // notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    // notificacionServidor('error', error.response.data.message)
                }
            }else{
                // notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            // notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const CargarSellinReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    const { 
        rex_errors_sell_in,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('carga_sellin', file); // archivo es la referencia al archivo que deseas enviar
    });

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile);
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/upload-sellin', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                // notificacionServidor('success', rquest.data.message)
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                const rquest = error.response
                // dispatch({
                //     type    : ERRORS_SELL_IN,
                //     payload : [...rex_errors_sell_in, ...error.response.data.messages_error]
                // })

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                    // notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    // notificacionServidor('error', error.response.data.message)
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const UploadSellinGrowReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {
    // const { rex_data_user_login } = getState().login
    // let rpta = true
    // if(rex_data_user_login.usuid == 1){
    //     rpta = await dispatch(UploadSellinGrowAdminReducer(fileList, deleteData, titleFile))
    // }else{
    //     rpta = await dispatch(UploadSellinGrowUserReducer(fileList, deleteData, titleFile))
    // }

    // return rpta
    return true
}

export const UploadSellinGrowUserReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    const { 
        rex_errors_sell_in,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('carga_sellin', file) // archivo es la referencia al archivo que deseas enviar
    })

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile)
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/upload-sellin-grow', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                const rquest = error.response

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                }else{
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const UploadSellinGrowAdminReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {
    const formData = new FormData()

    const { 
        rex_errors_sell_in,
        rex_msgs_notificaciones
    } = getState().uploadFile

    let borrar_data_value = []
    let number_records = 0
    let notificaciones = []
    let messages_error = []
    let error_log = []
    let message = ""
    let respuesta = ""
    let res_header = true
    
    fileList.forEach((file) => {
        formData.append('carga_sellin', file) // archivo es la referencia al archivo que deseas enviar
    })

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile)
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const sellin = await axios.post(config.api_url+'carga-archivos/upload-sellin-grow-toform', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(sellin.status){
            if(sellin.status == 200){
                borrar_data_value   = sellin.data.borrar_data_value
                number_records      = sellin.data.number_records
                error_log           = sellin.data.error_log
                message             = sellin.data.message
                respuesta           = sellin.data.respuesta
                res_header          = sellin.data.res_header
            }
        }
    } catch (error) {
        if(error.response){
            if(error.response.data){
                notificaciones      = error.response.data.notificaciones
                messages_error      = error.response.data.messages_error
                error_log           = error.response.data.error_log
                message             = error.response.data.message
                respuesta           = error.response.data.respuesta
                res_header          = error.response.data.res_header
            }
        }
    }

    if(respuesta){
        let indice = 1
        formData.append('req_borrar_data_value', JSON.stringify(borrar_data_value))
        formData.append('req_notificaciones', JSON.stringify([]))
        formData.append('req_messages_error', JSON.stringify([]))
        formData.append('req_error_log', JSON.stringify(error_log))
        formData.append('req_counter_first_data', 1)
        formData.append('req_counter_last_data', number_records)
        formData.append('req_error', false)
        formData.append('req_val_header', res_header)
        let container_number_records = []
        for (let index = 1; index <= number_records; index++) {
            container_number_records.push(index)
        }

        for await (const data of container_number_records) {
            try {
                formData.append('req_position', indice)
                const rquest = await axios.post(config.api_url+'carga-archivos/upload-sellin-grow-parts', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'usutoken'	   : localStorage.getItem('usutoken'),
                    },
                })

                if(rquest.status){
                    if(rquest.status == 200){
                        if(number_records == indice){
                            rex_msgs_notificaciones.push({
                                "titulo"  : "Notificación N°"+numero_notificacion,
                                "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                                "respuesta" : rquest.data.respuesta,
                                "data" : []
                            })
            
                            dispatch({
                                type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                                payload : rex_msgs_notificaciones
                            })
                        }
                    }
                }
            } catch (error) {
                console.log("Error " + error);
            }
            formData.delete('req_position');
            indice++
        }
    }else{
        formData.append('req_borrar_data_value', JSON.stringify([]))
        formData.append('req_notificaciones', JSON.stringify(notificaciones))
        formData.append('req_messages_error', JSON.stringify(messages_error))
        formData.append('req_error_log', JSON.stringify(error_log))
        formData.append('req_error', true)
        formData.append('req_val_header', res_header)
        try {
            await axios.post(config.api_url+'carga-archivos/upload-sellin-grow-parts', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'usutoken'	   : localStorage.getItem('usutoken'),
                },
            })
        } catch (error) {
            rpta = false
            if(error.response){
                if(error.response.data){
                    const mensagges = error.response.data.messages_error
                    const rquest = error.response

                    rex_msgs_notificaciones.push({
                        "titulo"  : "Notificación N°"+numero_notificacion,
                        "mensaje" : rquest.data.message,
                        "respuesta" : rquest.data.respuesta,
                        "data" : rquest.data.notificaciones
                    })

                    dispatch({
                        type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                        payload : rex_msgs_notificaciones
                    })

                    if(mensagges){
                        let msg_error_html = ""
                        mensagges.map((msg) => {
                            msg_error_html+= '<div>'+msg+'</div>'
                        })
                    }
                }else{
                    notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }
    }

    return rpta
}

export const CargarInventariosReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const { 
        rex_msgs_notificaciones
    } = getState().uploadFile

    const formData = new FormData();
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    fileList.forEach((file) => {
        formData.append('carga_inventarios', file); // archivo es la referencia al archivo que deseas enviar
    });

    const actions_file = { process_data : false, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    
    let rpta = true

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/inventories', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                    notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    notificacionServidor('error', error.response.data.message)
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const CargarMaestraPreciosReducer = ( fileList, deleteData, titleFile, date ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    const { 
        rex_errors_master_price,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('master_precios', file); // archivo es la referencia al archivo que deseas enviar
    });
    const actions_file = { process_data : true, delete_data : false}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile);
    formData.append('req_date_updated', date);
    formData.append('req_plataforma', JSON.stringify(true));
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/master-precios', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                // notificacionServidor('success', rquest.data.message)
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);

        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                const rquest = error.response

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.mensaje,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones,
                    // "error_excel": rquest.data.sheet_not_found
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                    // notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    // notificacionServidor('error', error.response.data.message)
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const UploadCargarMaestraPreciosReducer = ( fileList, deleteData, titleFile, date ) => async ( dispatch, getState ) => {
    const formData = new FormData()

    const { 
        rex_errors_master_price,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('master_precios', file); // archivo es la referencia al archivo que deseas enviar
    });

    let number_records = 0
    let notificaciones = []
    let messages_error = []
    let err_message_methods = []
    let message = ""
    let respuesta = null
    let res_header = true

    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile)
    formData.append('req_plataforma', JSON.stringify(true))
    
    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        const prices = await axios.post(config.api_url+'carga-archivos/master-precios-toform', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        if(prices.status){
            if(prices.status == 200){
                number_records      = prices.data.number_records
                err_message_methods = prices.data.err_message_methods
                message             = prices.data.message
                respuesta           = prices.data.respuesta
                res_header          = prices.data.res_header
            }
        }
    } catch (error) {
        if(error.response){
            if(error.response.data){
                notificaciones      = error.response.data.notificaciones
                messages_error      = error.response.data.messages_error
                err_message_methods = error.response.data.err_message_methods
                message             = error.response.data.message
                respuesta           = error.response.data.respuesta
                res_header          = error.response.data.res_header
            }
        }
    }

    if(respuesta){
        let indice = 1
        formData.append('req_notificaciones', JSON.stringify([]))
        formData.append('req_messages_error', JSON.stringify([]))
        formData.append('req_err_message_methods', JSON.stringify(err_message_methods))
        formData.append('req_counter_first_data', 1)
        formData.append('req_counter_last_data', number_records)
        formData.append('req_message', message)
        formData.append('req_state', true)
        formData.append('req_val_header', res_header)
        let container_number_records = []
        for (let index = 1; index <= number_records; index++) {
            container_number_records.push(index)
        }

        for await (const data of container_number_records) {
            try {
                formData.append('req_position', indice)
                const rquest = await axios.post(config.api_url+'carga-archivos/master-precios-parts', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'usutoken'	   : localStorage.getItem('usutoken'),
                    },
                })

                if(rquest.status){
                    if(rquest.status == 200){
                        if(number_records == indice){
                            rex_msgs_notificaciones.push({
                                "titulo"    : "Notificación N°"+numero_notificacion,
                                "mensaje"   : rquest.data.message,
                                "respuesta" : rquest.data.respuesta,
                                "data"      : []
                            })
            
                            dispatch({
                                type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                                payload : rex_msgs_notificaciones
                            })

                            rpta = rquest.data.respuesta
                        }
                    }
                }
            } catch (error) {
                console.log("Error " + error);
            }
            formData.delete('req_position');
            indice++
        }
    }else{
        formData.append('req_notificaciones', JSON.stringify(notificaciones))
        formData.append('req_messages_error', JSON.stringify(messages_error))
        formData.append('req_err_message_methods', JSON.stringify(err_message_methods))
        formData.append('req_message', message)
        formData.append('req_state', false)
        formData.append('req_val_header', res_header)
        try {
            await axios.post(config.api_url+'carga-archivos/master-precios-parts', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'usutoken'	   : localStorage.getItem('usutoken'),
                },
            })
        } catch (error) {
            rpta = false
            if(error.response){
                if(error.response.data){
                    const mensagges = error.response.data.messages_error
                    const rquest = error.response

                    rex_msgs_notificaciones.push({
                        "titulo"  : "Notificación N°"+numero_notificacion,
                        "mensaje" : rquest.data.message,
                        "respuesta" : rquest.data.respuesta,
                        "data" : rquest.data.notificaciones
                    })

                    dispatch({
                        type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                        payload : rex_msgs_notificaciones
                    })

                    if(mensagges){
                        let msg_error_html = ""
                        mensagges.map((msg) => {
                            msg_error_html+= '<div>'+msg+'</div>'
                        })
                    }
                }else{
                    notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }
    }

    return rpta
}

export const CargarClientesReducer = ( fileList, deleteData, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    const { 
        rex_errors_master_clients,
        rex_msgs_notificaciones
    } = getState().uploadFile
    
    fileList.forEach((file) => {
        formData.append('maestra_cliente', file); // archivo es la referencia al archivo que deseas enviar
    });
    const actions_file = { process_data : true, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_type_file', titleFile);
    formData.append('req_plataforma', JSON.stringify(true));

    let rpta = true
    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    try {
        // const rquest = await axios.post(config.api_url+'carga-archivos/master-clientes', formData, {
        const rquest = await axios.post(config.api_url+'carga-archivos/master-clientes-grow', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
        })

        //Numero de clientes que no se pudieron eliminar
        const notDeletedClients = rquest.data.notDeletedClients;

        //Numero de clientes creados
        const numberOfCreatedClients = rquest.data.createdClients;

        //Numero de clientes creados
        const numberOfDeletedClients = rquest.data.numberOfDeletedClients;

        if(rquest.status){
            if(rquest.status == 200){
                // notificacionServidor('success', rquest.data.message)
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `Archivo de ${titleFile}: Se crearon ${numberOfCreatedClients} nuevos registros -- Se eliminaron ${numberOfDeletedClients}
                    -- No se pudieron eliminar ${notDeletedClients} previos registros por estar en uso`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                console.log('request clients: ', rquest)

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);
        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.message
                const rquest = error.response
                // dispatch({
                //     type    : ERRORS_MASTER_CLIENTS,
                //     payload : [...rex_errors_master_clients, ...error.response.data.messages_error]
                // })

                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : rquest.data.message,
                    "respuesta" : rquest.data.respuesta,
                    "data" : rquest.data.notificaciones
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })

                if(mensagges){
                    // let msg_error_html = ""
                    // mensagges.map((msg) => {
                    //     msg_error_html+= '<div>'+msg+'</div>'
                    // })
                    // notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    // notificacionServidor('error', error.response.data.message)
                }
            }else{
                // notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            // notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const CargarListaPreciosReducer = ( fileList, deleteDat, titleFile ) => async ( dispatch, getState ) => {

    const formData = new FormData();

    const { 
        rex_msgs_notificaciones
    } = getState().uploadFile

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1

    
    fileList.forEach((file) => {
        formData.append('lista_precio', file); // archivo es la referencia al archivo que deseas enviar
    });
    const actions_file = { process_data : false, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file))

    let rpta = true

    try {
        const rquest = await axios.post(config.api_url+'administration/create-list-price', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.respuesta,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);
        rpta = false
        if(error.response){
            if(error.response.data){
                const mensagges = error.response.data.messages_error
                if(mensagges){
                    let msg_error_html = ""
                    mensagges.map((msg) => {
                        msg_error_html+= '<div>'+msg+'</div>'
                    })
                    notificacionServidor('error', msg_error_html, error.response.data.message)
                }else{
                    notificacionServidor('error', error.response.data.message)
                }
            }else{
                notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
            }
        }else{
            notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
        }
    }

    return rpta
}

export const CargarArchivoS3Reducer = ( fileList, deleteDat, titleFile ) => async ( dispatch, getState ) => {
    
    const { 
        rex_msgs_notificaciones
    } = getState().uploadFile

    const formData = new FormData();

    const numero_notificacion = parseInt(rex_msgs_notificaciones.length) < 9 ?"0"+(parseInt(rex_msgs_notificaciones.length) + 1) :parseInt(rex_msgs_notificaciones.length) + 1
    
    fileList.forEach((file) => {
        formData.append('file_s3', file); // archivo es la referencia al archivo que deseas enviar
    });

    const actions_file = { process_data : false, delete_data : true}
    formData.append('req_action_file', JSON.stringify(actions_file));
    formData.append('req_type_file', titleFile);

    let rpta = true

    try {
        const rquest = await axios.post(config.api_url+'carga-archivos/guardar-s3', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'usutoken' : localStorage.getItem('usutoken')
            },
        })

        if(rquest.status){
            if(rquest.status == 200){
                rex_msgs_notificaciones.push({
                    "titulo"  : "Notificación N°"+numero_notificacion,
                    "mensaje" : `El archivo de ${titleFile} se subio correctamente`,
                    "respuesta" : rquest.data.response,
                    "data" : []
                })

                dispatch({
                    type    : MSGS_NOTIFICACIONES_UPLOAD_FILE,
                    payload : rex_msgs_notificaciones
                })
            }
        }
        
    } catch (error) {
        console.error('Error al enviar el archivo:', error);
        rpta = false
        notificacionServidor('error', "Lo sentimos, tuvimos un error en el servidor")
    }

    return rpta
}